import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import Typography from '../../../styles/typography';
import Colors from '../../../styles/colors';

import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0 ${(props) => props.padding}rem;
  color: ${Colors.black};
  font-family: ${Typography.text.name};
  font-style: normal;
  font-weight: ${Typography.text.normal};
  font-size: ${Typography.text.sizes.normal};
  position: absolute;
  top: 0;
  visibility: hidden;
  height: auto;
  width: auto;
  white-space: nowrap;
`;

const CalculateTextWidth = ({ onHandleTabWidth, text, padding = 1 }) => {
  const ref = useRef();
  useEffect(() => {
    const width = ReactDOM.findDOMNode(ref.current).clientWidth;
    onHandleTabWidth(width);
  }, []);
  return (
    <Wrapper ref={ref} padding={padding}>
      {text}
    </Wrapper>
  );
};

export default CalculateTextWidth;
