import React from 'react';
import PropTypes from 'prop-types';

import Star from './star.svg';
import IconWrapper from '../icon-wrapper';

/**
 * [See current version in abstract]
 * (https://share.goabstract.com/a244a93d-3bfc-4350-bb11-ae006ef1f0d3)
 */
export default function StarIcon({ scale, altText }) {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      <Star />
    </IconWrapper>
  );
}

StarIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string
};

StarIcon.defaultProps = {
  scale: 1
};
