import React from 'react';
import PropTypes from 'prop-types';
import { StyledSectionContainer } from './styled-components';

/**
 * The SectionContainer adds padding to the sides depending on the screen size of the device by default.
 * Can be turned off (removeDefaultPadding: 'true') or overrided on different devices
 * (example: paddingAllSidesDesktopOnly: 'high' sets high padding on desktop but keeps default on tablet and mobile).
 *
 * The SectionContainer is used to wrap groups of related content (All other content should always be wrapped by a SectionContainer).
 * Can also set different options for margin and padding on all or some devices. Background color can be set on all or some devices.
 *
 * The option mobileOffset removes some extra padding from default padding on mobile to allow content to exeed the default boundaries
 * to make more room for content that lives inside boxes (like a KPI component for instance).
 *
 * Note: The values 'lowest' 'low', 'medium', 'high', is set to the different values on different devices allow us to grow the whitespace
 * as the screen gets bigger. Not like in ContentContainer where they are of the same values on all devices.
 */

export default function SectionContainer(props) {
  return (
    <StyledSectionContainer className={props.className} styling={props.styling}>
      {props.children}
    </StyledSectionContainer>
  );
}

SectionContainer.propTypes = {
  /** Content that should be wrapped with this container */
  children: PropTypes.node,
  styling: PropTypes.shape({
    /** Turns of default padding */
    removeDefaultPadding: PropTypes.bool,
    /** Turns on mobile offset */
    mobileOffset: PropTypes.bool,
    /** Sets bakground-color for all devices. One of 'white', 'header', 'footer', 'history' (Same goes for background overrides bellow) */
    backgroundColor: PropTypes.oneOf(['white', 'header', 'footer', 'history']),
    /** Overrides / sets bakground-color on desktop only*/
    backgroundColorDesktopOnly: PropTypes.oneOf([
      'white',
      'header',
      'footer',
      'history'
    ]),
    /** Overrides / sets bakground-color on tablet only*/
    backgroundColorTabletOnly: PropTypes.oneOf([
      'white',
      'header',
      'footer',
      'history'
    ]),
    /** Overrides / sets bakground-color on mobile only*/
    backgroundColorMobileOnly: PropTypes.oneOf([
      'white',
      'header',
      'footer',
      'history'
    ]),
    /** Sets padding on all sides. One of 'none' 'lowest' 'low', 'medium', 'high' (Same goes for all padding options bellow)*/
    paddingAllSides: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Sets padding-top for every device*/
    paddingTop: PropTypes.oneOf(['none', 'lowest', 'low', 'medium', 'high']),
    /** Sets padding-bottom for every device*/
    paddingBottom: PropTypes.oneOf(['none', 'lowest', 'low', 'medium', 'high']),
    /** Sets padding-left for every device*/
    paddingLeft: PropTypes.oneOf(['none', 'lowest', 'low', 'medium', 'high']),
    /** Sets padding-right for every device*/
    paddingRight: PropTypes.oneOf(['none', 'lowest', 'low', 'medium', 'high']),
    /** Overrides / sets padding all sides on desktop*/
    paddingAllSidesDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides / sets padding-top on desktop*/
    paddingTopDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides / sets padding-bottom on desktop*/
    paddingBottomDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides / sets padding-left on desktop*/
    paddingLeftDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides / sets padding-right on desktop*/
    paddingRightDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding all sides on tablet*/
    paddingAllSidesTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding-top on tablet*/
    paddingTopTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding-bottom on tablet*/
    paddingBottomTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding-left on tablet*/
    paddingLeftTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding-right on tablet*/
    paddingRightTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding all sides on mobile*/
    paddingAllSidesMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding-top on mobile*/
    paddingTopMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding-bottom on mobile*/
    paddingBottomMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding-left on mobile*/
    paddingLeftMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides padding-right on mobile*/
    paddingRightMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Sets margin on all sides. One of 'none' 'lowest' 'low', 'medium', 'high' (Same goes for all margin options bellow)*/
    marginAllSides: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Sets margin-top for every device*/
    marginTop: PropTypes.oneOf(['none', 'lowest', 'low', 'medium', 'high']),
    /** Sets margin-bottom for every device*/
    marginBottom: PropTypes.oneOf(['none', 'lowest', 'low', 'medium', 'high']),
    /** Sets margin-left for every device*/
    marginLeft: PropTypes.oneOf(['none', 'lowest', 'low', 'medium', 'high']),
    /** Sets margin-right for every device*/
    marginRight: PropTypes.oneOf(['none', 'lowest', 'low', 'medium', 'high']),
    /** Overrides / sets margin on all sides on desktop*/
    marginAllSidesDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Sets margin-top on desktop*/
    marginTopDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Sets margin-bottom on desktop*/
    marginBottomDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Sets margin-left on desktop*/
    marginLeftDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Sets margin-right on desktop*/
    marginRightDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin all sides on tablet*/
    marginAllSidesTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin-top on tablet*/
    marginTopTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin-bottom on tablet*/
    marginBottomTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin-left on tablet*/
    marginLeftTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin-right on tablet*/
    marginRightTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin all sides on mobile*/
    marginAllSidesMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin-top on mobile*/
    marginTopMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin-bottom on mobile*/
    marginBottomMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin-left on mobile*/
    marginLeftMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ]),
    /** Overrides margin-right on mobile*/
    marginRightMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'low',
      'medium',
      'high'
    ])
  })
};
