import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  AdditionalEntryWrapper,
  AdditionalEntriesWrapper,
  CollapsedEntry,
  EntryWrapper,
  ExpandIndicator,
  ListEntry,
  ListWrapper
} from './styled-expanding-list';

/**
 * The expandable list allows for the creation of a list that contains rows which can be expanded with more details.
 * It handles an array of rows. Each row can contain additional entries that become visible/hidden by clicking the row.
 *
 * Main entries and any additional entries within this is expected to be pre-styled elements.
 * The list itself will add no special styling except the arrow indicating that the row has additional details.
 *
 * The component also has functions that handle either opening or closing all the additional entries.
 * This is meant to be handled from the parent component.
 */

export default class ExpandingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRows: []
    };
  }

  handleClick(rowId) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.indexOf(rowId) > -1;
    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);

    this.setState(() => {
      return {
        expandedRows: newExpandedRows
      };
    });
  }

  renderAllEntries() {
    return this.props.list.map((entry, index) => {
      const hasAdditionalEntries =
        entry.additionalEntries && entry.additionalEntries.length > 0;
      const currentExpandedRows = this.state.expandedRows;
      const isExpanded = currentExpandedRows.indexOf(entry.rowId) > -1;

      return (
        <ListEntry key={index}>
          {this.renderEntry(entry, hasAdditionalEntries, isExpanded)}
          {isExpanded && hasAdditionalEntries ? (
            <AdditionalEntriesWrapper>
              {this.renderAdditionalEntries(entry.additionalEntries)}
            </AdditionalEntriesWrapper>
          ) : null}
        </ListEntry>
      );
    });
  }

  renderEntry(entry, hasAdditionalEntries, isExpanded) {
    const onClickFunction = () => this.handleClick(entry.rowId);
    return (
      <CollapsedEntry
        onClick={hasAdditionalEntries && onClickFunction}
        additionalEntries={hasAdditionalEntries}
      >
        {hasAdditionalEntries ? (
          <ExpandIndicator expanded={isExpanded} />
        ) : null}
        <EntryWrapper>{entry.mainEntry}</EntryWrapper>
      </CollapsedEntry>
    );
  }

  renderAdditionalEntries(additionalEntries) {
    return additionalEntries.map((entry, index) => {
      return (
        <AdditionalEntryWrapper key={index}>{entry}</AdditionalEntryWrapper>
      );
    });
  }

  closeAll() {
    this.setState(() => {
      return {
        expandedRows: []
      };
    });
  }

  openAll() {
    const allExpandedRows = [];
    this.props.list.forEach((row) => {
      if (row.additionalEntries) {
        allExpandedRows.push(row.rowId);
      }
    });
    this.setState(() => {
      return {
        expandedRows: allExpandedRows
      };
    });
  }

  render() {
    return (
      <ListWrapper data-cy={'expanding-list'}>
        {this.renderAllEntries()}
      </ListWrapper>
    );
  }
}

ExpandingList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      rowId: PropTypes.number,
      mainEntry: PropTypes.node,
      additionalEntries: PropTypes.arrayOf(PropTypes.node)
    })
  )
};
