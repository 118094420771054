import React from 'react';
import PropTypes from 'prop-types';

import LogOut from './log-out.svg';
import IconWrapper from '../icon-wrapper';

export default function LogOutIcon({ scale, altText }) {
  return (
    <IconWrapper
      width={1.6875 * scale}
      height={1.3125 * scale}
      aria-label={altText}
    >
      <LogOut />
    </IconWrapper>
  );
}

LogOutIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string
};

LogOutIcon.defaultProps = {
  scale: 1
};
