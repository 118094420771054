import styled from 'styled-components';

const ConfirmDialogMessageWrapper = styled.div`
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
  opacity: 0.9;
  max-width: 20rem;
`;

export default ConfirmDialogMessageWrapper;
