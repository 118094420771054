import styled from 'styled-components';
import Colors from '../../../styles/colors';

export const ErrorMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: thin solid ${Colors.darkGray};
  width: 100%;
  border-radius: 0.125rem;
`;

export const MessageWrapper = styled.div`
  text-align: center;
  max-width: 20rem;
  padding: 2rem;
  box-sizing: border-box;
  span {
    display: block;
  }
  > * {
    margin: 0.5rem 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 22vh;
`;

export const TextWrapper = styled.div`
  span {
    margin: 0.25rem 0;
  }
`;

export const ButtonWrapper = styled.div``;
