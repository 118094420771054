import styled from 'styled-components';
import { StyledFlexGridItem } from '../flex-grid-item/styled-components';

export const FlexGridWrapper = styled.div`
  overflow: hidden;
`;

export const FlexGridSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0
    ${(props) =>
      [['-' + props.style.flexGridGap], ['-' + props.style.flexGridGap]].join(
        ' '
      )}
    0;

  ${StyledFlexGridItem} {
    box-sizing: border-box;
    width: ${(props) => props.style.flexGridColumnWidth};
    background-clip: padding-box;
    border: 0 solid transparent;
    border-width: 0
      ${(props) =>
        [[props.style.flexGridGap], [props.style.flexGridGap]].join(' ')}
      0;

    > div {
      width: 100%;
    }
  }

  @media (max-width: ${(props) => props.style.flexGapAdjustmentBreakpoint}) {
    margin: 0
      ${(props) =>
        [
          ['-' + props.style.flexGridGapAdjustment],
          ['-' + props.style.flexGridGapAdjustment]
        ].join(' ')}
      0;

    ${StyledFlexGridItem} {
      border-width: 0
        ${(props) =>
          [
            [props.style.flexGridGapAdjustment],
            [props.style.flexGridGapAdjustment]
          ].join(' ')}
        0;
    }
  }

  @media (max-width: ${(props) => props.style.flexGridBreakpoint1}) {
    ${StyledFlexGridItem} {
      width: ${(props) => props.style.flexGridColumnWidthBreakpoint1};
    }
  }

  @media (max-width: ${(props) => props.style.flexGridBreakpoint2}) {
    ${StyledFlexGridItem} {
      width: ${(props) => props.style.flexGridColumnWidthBreakpoint2};
    }
  }

  @supports (grid-auto-rows: 0) {
    margin: 0;

    ${StyledFlexGridItem} {
      border: 0;
      border-width: 0;
      width: 100%;
    }

    display: grid;
    grid-template-columns: ${(props) => props.style.cssGridColumns};
    grid-gap: ${(props) => props.style.cssGridGap};

    @media (max-width: ${(props) =>
        props.style.cssGridGapAdjustmentBreakpoint}) {
      grid-gap: ${(props) => props.style.cssGridGapAdjustment};
    }
  }
`;
