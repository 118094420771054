import React from 'react';
import PropTypes from 'prop-types';

import Concentrates from './concentrates.svg';
import IconWrapper from '../icon-wrapper';
import { ConcentratesIconStyle } from './styled-components';

/**
 * [See current version in abstract]
 * ()
 */

export default function ConcentratesIcon({ scale, altText, colorScheme }) {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      <ConcentratesIconStyle colorScheme={colorScheme}>
        <Concentrates />
      </ConcentratesIconStyle>
    </IconWrapper>
  );
}

ConcentratesIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string,
  /**
   * Available color schemes. If not defined, the default SVG colors will be used.
   */
  colorScheme: PropTypes.oneOf(['default'])
};

ConcentratesIcon.defaultProps = {
  scale: 1,
  altText: 'concentrates-icon',
  colorScheme: 'default'
};
