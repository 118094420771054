import React from 'react';
import PropTypes from 'prop-types';

import Messages from './messages.svg';
import IconWrapper from '../icon-wrapper';

/**
 * [See current version in abstract]
 * (https://share.goabstract.com/f96513b5-66e2-44ea-b4f1-35d6755b8e56)
 */

export default function MessagesIcon({ scale, altText }) {
  return (
    <IconWrapper width={1.4 * scale} height={1 * scale} aria-label={altText}>
      <Messages />
    </IconWrapper>
  );
}

MessagesIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string
};

MessagesIcon.defaultProps = {
  scale: 1
};
