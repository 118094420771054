import styled from 'styled-components';

export const StyledGridContainer = styled.div`
  display: -ms-grid;
  display: grid;

  -ms-grid-columns: minmax(35rem, 1fr) minmax(15rem, 23rem) 2rem;

  grid-template-columns: minmax(35rem, 1fr) minmax(15rem, 23rem) 2rem;
  -ms-grid-rows: -webkit-min-content 2rem 1fr 2rem;
  -ms-grid-rows: min-content 2rem 1fr 2rem;
  grid-template-rows: -webkit-min-content 2rem 1fr 2rem;
  grid-template-rows: min-content 2rem 1fr 2rem;
  grid-template-areas:
    'header  header  header'
    '.  .  .'
    'main  history  .'
    '.  .  .';

  .innerGrid__x {
    display: -ms-grid;
    display: grid;

    -ms-grid-columns: 2fr 1fr;

    grid-template-columns: 2fr 1fr;

    .buttons__x {
      -ms-grid-column: 1;
      grid-column: 1;
    }
  }

  .header__x {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: header;
  }
  .content__x {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    grid-area: main;
  }
  .historySection__x {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    grid-area: history;
  }

  @media (max-width: 900px) {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: -webkit-min-content 2rem -webkit-min-content -webkit-min-content;
    -ms-grid-rows: min-content 2rem min-content min-content;
    grid-template-rows: -webkit-min-content 2rem -webkit-min-content -webkit-min-content;
    grid-template-rows: min-content 2rem min-content min-content;
    grid-template-areas:
      'header'
      '.'
      'main'
      'history';

    .innerGrid__x {
      -ms-grid-columns: 1fr 3fr 1fr;
      grid-template-columns: 1fr 3fr 1fr;

      .buttons__x {
        -ms-grid-column: 2;
        grid-column: 2;
      }
    }

    .header__x {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
    }

    .content__x {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
    }

    .historySection__x {
      -ms-grid-row: 4;
      -ms-grid-column: 1;
    }
  }
`;
