import React from 'react';

export default (Link) => {
  return (props) => {
    const { style, ...passthroughProps } = props;

    let mutatedStyle = { ...style };
    if (props.disabled) {
      mutatedStyle.pointerEvents = 'none';
    }

    function handleClick(e) {
      if (props.disabled) {
        e.preventDefault();
      }
    }

    return (
      <Link onClick={handleClick} style={mutatedStyle} {...passthroughProps} />
    );
  };
};
