import AddIcon from './add-icon';
import AlertIcon from './alert-icon';
import InfoIcon from './info-icon';
import CheckMarkIcon from './check-mark-icon';
import AnimalCardIcon from './animal-card-icon';
import MilkReceiptIcon from './milk-receipt-icon';
import CalculatedResultIcon from './calculated-result-icon';
import GraphIcon from './graph-icon';
import ArrowDownIcon from './arrow-down-icon';
import ArrowLeftIcon from './arrow-left-icon';
import ArrowRightIcon from './arrow-right-icon';
import ArrowUpIcon from './arrow-up-icon';
import BackIcon from './back-icon';
import CrossIcon from './cross-icon';
import CheckIcon from './check-icon';
import TriangleLeftIcon from './triangle-left-icon';
import TriangleRightIcon from './triangle-right-icon';
import SearchIcon from './search-icon';
import StarIcon from './star-icon';
import SunIcon from './sun-icon';
import FeedSettingsIcon from './feed-settings-icon';
import GrowingGrasIcon from './growing-gras-icon';
import KpiIcon from './kpi-icon';
import MessagesIcon from './messages_icon';
import LivestockIcon from './livestock-icon';
import CarrotIcon from './carrot-icon';
import RadishIcon from './radish-icon';
import RoundbaleIcon from './roundbale-icon';
import ConcentratesIcon from './concentrates-icon';
import BarleyIcon from './barley-icon';
import CornIcon from './corn-icon';
import SiloIcon from './silo-icon';
import ConcentratesSiloIcon from './concentrates-silo-icon';
import QuantityIcon from './quantity-icon';
import DeliveryIcon from './delivery-icon';
import OtherToolsIcon from './other-tools-icon';
import TbsIcon from './tbs-icon';
import FarmIcon from './farm-icon';
import LogOutIcon from './log-out-icon';
import MimiroIcon from './mimiro-icon';
import MimiroVerticalIcon from './mimiro-vertical-icon';
import EanaIcon from './eana-icon';
import EanaAppIcon from './eana-app-icon';
import TrashcanIcon from './trashcan-icon';
import CloudSyncIcon from './cloud-sync-icon';

global.AddIcon = AddIcon;
global.AlertIcon = AlertIcon;
global.InfoIcon = InfoIcon;
global.CheckMarkIcon = CheckMarkIcon;
global.AnimalCardIcon = AnimalCardIcon;
global.MilkReceiptIcon = MilkReceiptIcon;
global.CalculatedResultIcon = CalculatedResultIcon;
global.GraphIcon = GraphIcon;
global.ArrowLeftIcon = ArrowLeftIcon;
global.ArrowUpIcon = ArrowUpIcon;
global.ArrowRightIcon = ArrowRightIcon;
global.ArrowDownIcon = ArrowDownIcon;
global.BackIcon = BackIcon;
global.CrossIcon = CrossIcon;
global.CheckIcon = CheckIcon;
global.SearchIcon = SearchIcon;
global.StarIcon = StarIcon;
global.SunIcon = SunIcon;
global.GrowingGrasIcon = GrowingGrasIcon;
global.KpiIcon = KpiIcon;
global.MessagesIcon = MessagesIcon;
global.LivestockIcon = LivestockIcon;
global.CarrotIcon = CarrotIcon;
global.RadishIcon = RadishIcon;
global.RoundbaleIcon = RoundbaleIcon;
global.ConcentratesIcon = ConcentratesIcon;
global.BarleyIcon = BarleyIcon;
global.CornIcon = CornIcon;
global.SiloIcon = SiloIcon;
global.ConcentratesSiloIcon = ConcentratesSiloIcon;
global.QuantityIcon = QuantityIcon;
global.DeliveryIcon = DeliveryIcon;
global.FeedSettingsIcon = FeedSettingsIcon;
global.OtherToolsIcon = OtherToolsIcon;
global.TbsIcon = TbsIcon;
global.FarmIcon = FarmIcon;
global.LogOutIcon = LogOutIcon;
global.MimiroIcon = MimiroIcon;
global.MimiroVerticalIcon = MimiroVerticalIcon;
global.TriangleLeftIcon = TriangleLeftIcon;
global.TriangleRightIcon = TriangleRightIcon;
global.EanaIcon = EanaIcon;
global.EanaAppIcon = EanaAppIcon;
global.TrashcanIcon = TrashcanIcon;
global.CloudSyncIcon = CloudSyncIcon;

export {
  AddIcon,
  AlertIcon,
  InfoIcon,
  CheckMarkIcon,
  AnimalCardIcon,
  MilkReceiptIcon,
  CalculatedResultIcon,
  GraphIcon,
  ArrowLeftIcon,
  ArrowUpIcon,
  ArrowRightIcon,
  ArrowDownIcon,
  BackIcon,
  CrossIcon,
  CheckIcon,
  SearchIcon,
  StarIcon,
  SunIcon,
  GrowingGrasIcon,
  KpiIcon,
  MessagesIcon,
  LivestockIcon,
  CarrotIcon,
  RadishIcon,
  RoundbaleIcon,
  ConcentratesIcon,
  BarleyIcon,
  CornIcon,
  SiloIcon,
  ConcentratesSiloIcon,
  QuantityIcon,
  DeliveryIcon,
  FeedSettingsIcon,
  OtherToolsIcon,
  TbsIcon,
  FarmIcon,
  LogOutIcon,
  MimiroIcon,
  MimiroVerticalIcon,
  TriangleLeftIcon,
  TriangleRightIcon,
  EanaIcon,
  EanaAppIcon,
  TrashcanIcon,
  CloudSyncIcon
};
