import React from 'react';
import PropTypes from 'prop-types';

import ArrowRightIcon from '../../../elements/icons/arrow-right-icon';
import { ArrowIconWrapper } from './styled-expanding-container';

const ArrowButton = ({ expanded, expandable }) => {
  return (
    <ArrowIconWrapper expanded={expanded} expandable={expandable}>
      <ArrowRightIcon scale={0.5} />
    </ArrowIconWrapper>
  );
};

ArrowButton.propTypes = {
  /**
   * Boolean indicating if the container can be expanded or not. If `false`, the arrow will
   * point to the right.
   */
  expandable: PropTypes.bool,
  /**
   * Boolean indicating if the container should be expanded by default.
   */
  expanded: PropTypes.bool
};

ArrowButton.defaultProps = {
  expanded: false,
  expandable: true
};

export default ArrowButton;
