import React from 'react';
import PropTypes from 'prop-types';

import Corn from './corn.svg';
import IconWrapper from '../icon-wrapper';
import { CornIconStyle } from './styled-components';

/**
 * [See current version in abstract]
 * ()
 */

export default function CornIcon({ scale, altText, colorScheme }) {
  return (
    <IconWrapper width={1.5 * scale} height={2 * scale} aria-label={altText}>
      <CornIconStyle colorScheme={colorScheme}>
        <Corn />
      </CornIconStyle>
    </IconWrapper>
  );
}

CornIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string,
  /**
   * Available color schemes. If not defined, the default SVG colors will be used.
   */
  colorScheme: PropTypes.oneOf(['default'])
};

CornIcon.defaultProps = {
  scale: 1,
  altText: 'corn-icon',
  colorScheme: 'default'
};
