import styled from 'styled-components';
import Colors from '../../../styles/colors/colors';

export const ListWrapper = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const ListEntry = styled.li`
  list-style-type: none;
`;

export const CollapsedEntry = styled.div`
  display: flex;
  align-items: baseline;
  cursor: ${(props) => (props.additionalEntries ? 'pointer' : 'normal')};
  padding-left: ${(props) => (props.additionalEntries ? 0 : '1rem')};
`;

export const EntryWrapper = styled.div`
  width: 100%;
`;

export const AdditionalEntryWrapper = styled.div`
  margin-left: 1rem;
`;

export const AdditionalEntriesWrapper = styled.div`
  margin-top: 0.25rem;
`;

export const ExpandIndicator = styled.div`
  width: 0;
  height: 0;
  border-top: 0.25rem solid transparent;
  border-bottom: 0.25rem solid transparent;
  border-left: 0.4rem solid ${Colors.black};
  margin-right: 0.6rem;
  transform: rotate(${(props) => (props.expanded ? '90deg' : '0')});
  transition: transform 100ms ease-out;
`;
