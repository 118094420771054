import styled from 'styled-components';

const IconWrapper = styled.div`
  display: inline-block;
  width: ${(props) => props.width && props.width + 'rem'};
  height: ${(props) => props.height && props.height + 'rem'};
  min-width: ${(props) => props.width && props.width + 'rem'};
  min-height: ${(props) => props.height && props.height + 'rem'};

  div {
    height: ${(props) =>
      (props.height && props.height + 'rem !important;') || '100%'};
  }

  svg {
    height: ${(props) =>
      (props.height && props.height + 'rem !important;') || '100%'};
  }
`;

export default IconWrapper;
