import React from 'react';
import PropTypes from 'prop-types';
import { StyledFlexGridItem } from './styled-components';
/**
 * This container is used to wrap items that is used in AutoplacementGridSection to make the IE11 fallback Flex grid work.
 */

export default function FlexGridItem(props) {
  return <StyledFlexGridItem>{props.children}</StyledFlexGridItem>;
}

FlexGridItem.propTypes = {
  /** Content that should be wrapped with this container */
  children: PropTypes.node
};
