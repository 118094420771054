//flexGrid is a fallback grid for IE11

const gridTypes = {
  kpi: {
    cssGridColumns: 'repeat(auto-fill, minmax(18rem, 1fr))',
    cssGridGap: '1.5rem',
    cssGridGapAdjustmentBreakpoint: '1100px',
    cssGridGapAdjustment: '1rem',
    flexGridBreakpoint1: '1270px',
    flexGridBreakpoint2: '900px',
    flexGridColumnWidth: 'calc(99.999% / 3)',
    flexGridColumnWidthBreakpoint1: 'calc(99.999% / 2)',
    flexGridColumnWidthBreakpoint2: '100%',
    flexGridGap: '1.5rem',
    flexGapAdjustmentBreakpoint: '1100px',
    flexGridGapAdjustment: '1rem'
  },
  testResults: {
    cssGridColumns: 'repeat(auto-fill, minmax(20rem, 1fr))',
    cssGridGap: '1.5rem',
    cssGridGapAdjustmentBreakpoint: '1100px',
    cssGridGapAdjustment: '1rem',
    flexGridBreakpoint1: '1350px',
    flexGridBreakpoint2: '1000px',
    flexGridColumnWidth: 'calc(99.999% / 3)',
    flexGridColumnWidthBreakpoint1: 'calc(99.999% / 2)',
    flexGridColumnWidthBreakpoint2: '100%',
    flexGridGap: '1.5rem',
    flexGapAdjustmentBreakpoint: '1100px',
    flexGridGapAdjustment: '1rem'
  }
};

export default (kpi, testResults) => {
  if (kpi) {
    return gridTypes['kpi'];
  } else if (testResults) {
    return gridTypes['testResults'];
  } else {
    return gridTypes['kpi'];
  }
};
