import React from 'react';
import PropTypes from 'prop-types';

import Colors from '../../../../styles/colors/colors';
import EmphasisLight from '../../../../elements/typography/text/emphasis-light';

import { StyledLabel } from './styled-components';

const Label = ({ labelText, id, disabled }) => {
  return (
    <>
      {labelText && (
        <StyledLabel htmlFor={id}>
          <EmphasisLight
            size="small"
            color={disabled ? Colors.darkGray : Colors.black}
          >
            {labelText}
          </EmphasisLight>
        </StyledLabel>
      )}
    </>
  );
};

Label.propTypes = {
  //** A boolean to distinguish wheather it is a label applied to note input or common text input */
  noteInput: PropTypes.bool,
  /** Text for for the label */
  labelText: PropTypes.string,
  /** A unique id */
  id: PropTypes.string,
  /** A boolean to display appropriate color based on state */
  disabled: PropTypes.bool
};

export default Label;
