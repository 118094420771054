import React from 'react';
import PropTypes from 'prop-types';

import Silo from './silo.svg';
import IconWrapper from '../icon-wrapper';
import { SiloIconStyle } from './styled-components';

/**
 * [See current version in abstract]
 * ()
 */

export default function SiloIcon({ scale, altText, colorScheme }) {
  return (
    <IconWrapper width={1.5 * scale} height={1.9 * scale} aria-label={altText}>
      <SiloIconStyle colorScheme={colorScheme}>
        <Silo />
      </SiloIconStyle>
    </IconWrapper>
  );
}

SiloIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string,
  /**
   * Available color schemes. If not defined, the default SVG colors will be used.
   */
  colorScheme: PropTypes.oneOf(['default', 'green'])
};

SiloIcon.defaultProps = {
  scale: 1,
  altText: 'silo-icon',
  colorScheme: 'default'
};
