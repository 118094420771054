import PropTypes from 'prop-types';

import styled from 'styled-components';
import colors from '../../../styles/colors';

/* Sizes in rem */
const sizes = {
  large: {
    lineHeight: 1.6,
    width: 1.5,
    height: 1.5,
    paddingLeft: 3.5
  },
  small: {
    lineHeight: 1.3,
    width: 1,
    height: 1,
    paddingLeft: 2.75
  }
};

const StyledRadioButton = styled.div`
  display: flex;
  flex: 1;
  position: relative;

  input:checked,
  input:not(:checked) {
    position: absolute;
    opacity: 0;
    width: calc(${(props) => sizes[props.size].width + 'rem'} + 2px);
    height: calc(${(props) => sizes[props.size].height + 'rem'} + 2px);
    border-radius: 50%;
    margin: 0;
    left: 1rem;
    top: auto;
  }
  input:checked + label,
  input:not(:checked) + label {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: ${(props) => sizes[props.size].paddingLeft + 'rem'};
    min-height: ${(props) => sizes[props.size].lineHeight + 'rem'};
    cursor: pointer;
    line-height: ${(props) => sizes[props.size].lineHeight + 'rem'};
  }
  input:disabled + label:before {
    opacity: 0.5;
    cursor: default;
  }
  input:disabled + label {
    cursor: default;
  }
  input:checked + label:before,
  input:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 1rem;
    top: auto;
    width: ${(props) => sizes[props.size].width + 'rem'};
    height: ${(props) => sizes[props.size].height + 'rem'};
    border: thin solid ${colors.positive};
    border-radius: 100%;
    background: ${colors.positive};
  }
  input:not(:checked) + label:before {
    background-color: transparent;
    border-color: ${colors.darkGray};
  }

  input:checked + label:after,
  input:not(:checked) + label:after {
    position: absolute;
    content: '';
    width: ${(props) => sizes[props.size].width + 'rem'};
    height: ${(props) => sizes[props.size].height + 'rem'};
    top: 0;
    left: 1rem;
    transform: scale(0.25, 0.25);
    background: ${colors.white};

    border: solid thin ${colors.white};
    border-radius: 50%;
  }
  input:not(:checked) + label:after {
    opacity: 0;
  }
  input:checked + label:after {
    opacity: 1;
  }
  input:focus + label:before {
    box-shadow: 0 0 0.125rem 0.125rem ${colors.positiveShadeLight};
  }
`;

StyledRadioButton.propTypes = {
  size: PropTypes.oneOf(['small', 'large'])
};

StyledRadioButton.defaultProps = {
  size: 'large'
};

export default StyledRadioButton;
