import React from 'react';
import PropTypes from 'prop-types';

import EanaApp from './eana-app.svg';
import IconWrapper from '../icon-wrapper';

/**
 * [See current version in abstract]
 * (https://share.goabstract.com/8cd74d33-13bb-4a07-a76d-58e6c3dcc0ae)
 */

export default function EanaAppIcon({ scale, altText }) {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      <EanaApp />
    </IconWrapper>
  );
}

EanaAppIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string
};

EanaAppIcon.defaultProps = {
  scale: 1
};
