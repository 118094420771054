import React from 'react';
import { StyledEanaWebPageWrapper } from './styled-components';

/**
 * Wrapper component used in the apps as a wrapper around the page for the Eana Web Portal.
 * This wrapper would be the child component to the EanaWebBody.
 */

export default function EanaWebPageWrapper({ children }) {
  return <StyledEanaWebPageWrapper>{children}</StyledEanaWebPageWrapper>;
}
