import React from 'react';
import PropTypes from 'prop-types';

import CloudSync from './cloud-sync.svg';
import IconWrapper from '../icon-wrapper';

export default function CloudSyncIcon({ scale, altText }) {
  return (
    <IconWrapper width={2 * scale} height={1.5 * scale} aria-label={altText}>
      <CloudSync />
    </IconWrapper>
  );
}

CloudSyncIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string
};

CloudSyncIcon.defaultProps = {
  scale: 1
};
