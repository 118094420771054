import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import StyledClickableButton from '../common/styled-clickable-button';
import getStyle from '../common/button-styles';

/**
 * [See current version in abstract]
 * (https://app.goabstract.com/projects/86166c10-b73b-11e8-b61c-bbf42f55e4f3/branches/master/collections/c79c1e3f-dac3-474e-8c0c-c6612cd5defd)
 *
 * The Button component is often used in the application.
 *
 * It comes in tree different variations primary, secondary, tertiary and multi where primary is default.
 * It also has a disabled property.
 */
export default function ActionButton({
  children,
  secondary,
  tertiary,
  multi,
  warning,
  disabled,
  onClick,
  selected
}) {
  const touchStart = () => {};
  const callbackRef = useCallback((node) => {
    const domNode = ReactDOM.findDOMNode(node);
    if (domNode) {
      domNode.addEventListener('touchstart', touchStart, {
        capture: true,
        passive: true
      });
    }
    return () => {
      domNode.removeEventListener('touchstart', touchStart);
    };
  }, []);
  const style = getStyle(secondary, tertiary, multi, warning);

  return (
    <StyledClickableButton
      data-cy="action-button"
      disabled={disabled}
      style={style}
      onClick={onClick}
      selected={selected}
      ref={callbackRef}
    >
      {children}
    </StyledClickableButton>
  );
}

ActionButton.propTypes = {
  /** Text to show in button. */
  children: PropTypes.string.isRequired,
  /**
   * If true it would override the default style primary with the secondary style.
   */
  secondary: PropTypes.bool,
  /**
   * If true it would override the default style primary with the tertiary style.
   */
  tertiary: PropTypes.bool,

  /**
   * If true it would override the default style primary with the warning style.
   */
  warning: PropTypes.bool,

  /**
   * If true it would override the default style primary with the multi style.
   */
  multi: PropTypes.bool,
  /**
   * If true it would set the disabled style on the button associated with primary, secondary or tertiary.
   */
  disabled: PropTypes.bool,
  /**
   * Handler to be called when the button is clicked.
   */
  onClick: PropTypes.func,
  /**
   * If true mark button with selected border indicator
   */
  selected: PropTypes.bool
};
