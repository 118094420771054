import React from 'react';
import PropTypes from 'prop-types';

import Text from '../text';
import Typography from '../../../../styles/typography';

/**
 * The Emphasis component is recommended to be used often used for larger headers and texts of importants.
 *
 * It is using the Text component with the font weight bold instead of regular and has all the options that the Text component has.
 */

export default function Emphasis({ children, color, size, italic }) {
  return (
    <Text
      color={color}
      italic={italic}
      fontWeight={Typography.text.bold}
      size={size}
    >
      {children}
    </Text>
  );
}

Emphasis.propTypes = {
  /** Choose from the colorpalette which color you want the text to be displayed in. */
  color: PropTypes.string,
  /** If true the italic font style would be added for the text. */
  italic: PropTypes.bool,
  /** Choose the size:*/
  size: PropTypes.oneOf([
    'extrasmall',
    'small',
    'normal',
    'large',
    'extralarge'
  ])
};
