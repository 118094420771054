import styled from 'styled-components';
import Colors from '../../../styles/colors/colors';
import Typography from '../../../styles/typography/typography';

export default styled.div`
  font-family: ${Typography.text.name};

  .react-calendar__tile,
  .react-calendar__navigation__label,
  .react-calendar__navigation,
  .react-calendar__navigation__arrow {
    border: none;
    background: ${Colors.white};
    line-height: 2rem;
    box-sizing: border-box;

    abbr {
      font-size: ${Typography.text.sizes.normal};
      border-radius: 50%;
      width: 1.9rem;
      height: 1.9rem;
      display: inline-block;
      margin: 0.05rem;
    }

    &:focus {
      outline: 0.125rem solid ${Colors.secondary};
    }
  }

  .react-calendar__navigation__arrow {
    z-index: 1;
  }

  .react-calendar__navigation__label {
    font-size: unset;
    font-family: ${Typography.text.name};
  }

  abbr[title] {
    text-decoration: none;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${Colors.gray};
  }

  .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    line-height: 2rem;
    font-weight: ${Typography.text.semibold};
  }

  .react-calendar__month-view__days__day {
    cursor: pointer;
    margin: 0;
    padding: 0;

    &:disabled {
      cursor: default;
    }
  }

  .react-calendar__tile--now {
    font-weight: ${Typography.text.semibold};
  }

  .react-calendar__tile--rangeBothEnds {
    abbr {
      border: 0.125rem solid ${Colors.secondary} !important;
      background-color: ${Colors.secondaryShadeLight};
      color: ${Colors.black};
    }
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__label {
    color: ${Colors.black};
  }

  .react-calendar__navigation__arrow {
    width: 14.28%;
    cursor: pointer;
  }
  .react-calendar__month-view__days__day:focus {
    outline: none;
  }
  .react-calendar__month-view__days__day:focus > abbr {
    position: relative;
  }

  .react-calendar__month-view__days__day {
    abbr {
      border: 0.125rem solid ${Colors.white};
    }
  }

  .react-calendar__month-view__days__day:focus,
  .react-calendar__month-view__days__day:active {
    abbr {
      border: 0.125rem solid ${Colors.secondary};
      border-radius: 50%;
      color: ${Colors.black};
    }
  }

  .react-calendar__navigation__arrow:disabled {
    svg {
      path {
        stroke: ${Colors.gray};
        fill: ${Colors.gray};
      }
    }
  }
`;
