import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledBackground,
  StyledClose,
  StyledContent,
  StyledDialog,
  StyledHeader,
  GridLayout
} from './styled-components';

/**
 * [See current version in abstract]
 * https://share.goabstract.com/6d01b6bb-bb2c-46aa-ac38-b363534f4253
 *
 * Simple modal dialog component used to display information to the user.
 *
 * The component must be provided with an array of children and a show boolean and a onClose callback.
 * Alternatively the close icon can and header icon can be configured.
 */
export const ModalDialog = ({
  children,
  show,
  onClose,
  header,
  closeIcon,
  width,
  height,
  minWidth
}) => {
  function renderHeader() {
    if (header) {
      return (
        <StyledHeader width={width}>
          <StyledClose data-cy="modal-dialog-close-button" onClick={onClose}>
            {closeIcon}
          </StyledClose>
          {header}
        </StyledHeader>
      );
    } else {
      return (
        <StyledClose data-cy="modal-dialog-close-button" onClick={onClose}>
          {closeIcon}
        </StyledClose>
      );
    }
  }

  return (
    <StyledBackground
      data-cy="modal-dialog-background"
      show={show}
      onClick={onClose}
    >
      <GridLayout width={width} height={height} minWidth={minWidth}>
        <StyledDialog
          data-cy="modal-dialog"
          onClick={(e) => e.stopPropagation()}
          className="modal"
        >
          {renderHeader()}
          <StyledContent>{children}</StyledContent>
        </StyledDialog>
      </GridLayout>
    </StyledBackground>
  );
};

ModalDialog.defaultProps = {
  closeIcon: '×',
  width: {
    mobile: '90%',
    tablet: '80%',
    web: '50%'
  },
  height: {
    mobile: '95%',
    tablet: '75%',
    web: '75%'
  },
  minWidth: {
    mobile: '90%',
    tablet: '80%',
    web: '50%'
  }
};

ModalDialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.node,
  closeIcon: PropTypes.node,
  /** Set width for mobile, tablet, web in percent for the modal */
  width: PropTypes.shape({
    mobile: PropTypes.string,
    tablet: PropTypes.string,
    web: PropTypes.string
  }),
  /** Set height for mobile, tablet, web in percent for the modal */
  height: PropTypes.shape({
    mobile: PropTypes.string,
    tablet: PropTypes.string,
    web: PropTypes.string
  }),
  minWidth: PropTypes.shape({
    mobile: PropTypes.string,
    tablet: PropTypes.string,
    web: PropTypes.string
  })
};

export default ModalDialog;
