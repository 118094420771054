import React from 'react';
import PropTypes from 'prop-types';

import Quantity from './quantity.svg';
import IconWrapper from '../icon-wrapper';
import { QuantityIconStyle } from './styled-components';

/**
 * [See current version in abstract]
 * ()
 */

export default function QuantityIcon({ scale, altText, colorScheme }) {
  return (
    <IconWrapper width={1.75 * scale} height={2 * scale} aria-label={altText}>
      <QuantityIconStyle colorScheme={colorScheme}>
        <Quantity />
      </QuantityIconStyle>
    </IconWrapper>
  );
}

QuantityIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string,
  /**
   * Available color schemes. If not defined, the default SVG colors will be used.
   */
  colorScheme: PropTypes.oneOf(['default'])
};

QuantityIcon.defaultProps = {
  scale: 1,
  altText: 'quantity-icon',
  colorScheme: 'default'
};
