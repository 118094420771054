import React from 'react';
import PropTypes from 'prop-types';

import CheckMark from './input_check.svg';
import IconWrapper from '../icon-wrapper';

/**
 * [See current version in abstract]
 * (https://share.goabstract.com/74999f02-56d8-413c-a040-0afd476d7132)
 */

export default function CheckMarkIcon({ scale, altText }) {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      <CheckMark />
    </IconWrapper>
  );
}

CheckMarkIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string
};

CheckMarkIcon.defaultProps = {
  scale: 1
};
