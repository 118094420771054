import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Colors from '../../../styles/colors/colors';

import Text from '../../../elements/typography/text/text';
import Label from './label';
import ValidationIcon from './validation-icon';

import {
  StyledErrorWrapper,
  StyledInput,
  StyledNoteInput,
  StyledInputContainerInner,
  StyledInputContainerOuter,
  StyledInputWrapper,
  StyledUnitWrapper
} from './styled-components';
import useFocus from '../common/use-focus';

/**
 * [See current version in abstract]
 * (https://share.goabstract.com/d156cc17-705d-4868-bc4c-cd17ebc6c743)
 *
 * The Input component consists of a basic input-field, a title-label, a unit-label
 * and validation to provide feedback to the user when incorrect input is given.
 *
 */

const Input = ({
  noteInput,
  ariaLabel,
  disabled,
  errorMessage,
  labelText,
  id,
  name,
  onBlur,
  onChange,
  onFocus,
  pattern,
  placeholder,
  required,
  type,
  unit,
  valid,
  value,
  focus
}) => {
  const [htmlElRef, setFocus] = useFocus();

  function handleChange(event) {
    onChange(event);
  }

  function renderErrorMessage() {
    return (
      !disabled &&
      valid === false && (
        <StyledErrorWrapper>
          <Text color={Colors.negative} italic>
            {errorMessage}
          </Text>
        </StyledErrorWrapper>
      )
    );
  }

  function renderUnit() {
    return (
      unit && (
        <StyledUnitWrapper>
          <Text
            size="extralarge"
            color={disabled ? Colors.darkGray : Colors.black}
          >
            {unit}
          </Text>
        </StyledUnitWrapper>
      )
    );
  }

  function renderDefaultInput() {
    return (
      <StyledInput
        aria-label={ariaLabel}
        data-cy="validation-input"
        disabled={disabled}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        focus={focus}
        required={required}
        type={type}
        pattern={pattern}
        placeholder={placeholder}
        unit={unit}
        valid={valid}
        value={value}
        ref={htmlElRef}
      />
    );
  }

  function renderNoteInput() {
    return (
      <StyledNoteInput
        errorMessage={errorMessage}
        aria-label={ariaLabel}
        data-cy="validation-input"
        disabled={disabled}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        focus={focus}
        required={required}
        type={type}
        pattern={pattern}
        placeholder={placeholder}
        unit={unit}
        valid={valid}
        value={value}
        ref={htmlElRef}
      />
    );
  }

  useEffect(() => {
    if (focus) {
      setFocus();
    }
  }, [focus]);

  return (
    <StyledInputWrapper>
      {!noteInput && <Label labelText={labelText} id={id}></Label>}
      <StyledInputContainerOuter>
        <StyledInputContainerInner>
          {noteInput ? renderNoteInput() : renderDefaultInput()}
          {!noteInput && (
            <ValidationIcon disabled={disabled} valid={valid}></ValidationIcon>
          )}
        </StyledInputContainerInner>
        {renderUnit()}
      </StyledInputContainerOuter>
      {errorMessage && renderErrorMessage()}
    </StyledInputWrapper>
  );
};

Input.defaultProps = {
  type: 'text',
  valid: null
};

Input.propTypes = {
  /** A boolean to distinguish wheather it is a note input or common text input */
  noteInput: PropTypes.bool,
  /** A hidden-label read by screen-readers for accessibility */
  ariaLabel: PropTypes.string,

  /** A boolean specifying whether the input-field is disabled. */
  disabled: PropTypes.bool,

  /** An error message that is shown below the input-field if valid-prop is specified. */
  errorMessage: PropTypes.string,

  /** A label text for the input-field. */
  labelText: PropTypes.string,

  /** A unique id that is specified for both the label and input-field. */
  id: PropTypes.string,

  /** Name-attribute that can be specified for the input-field. */
  name: PropTypes.string,

  /** onBlur-attribute when input-field loses focus. */
  onBlur: PropTypes.func,

  /** onChange-attribute to change the current value of the input-field. */
  onChange: PropTypes.func.isRequired,

  /** onFocus-attribute when input-field is focused. */
  onFocus: PropTypes.func,

  /** A pattern-attribute that takes in a regular expression to validate the input-field. */
  pattern: PropTypes.string,

  /** A placeholder that hints about the value that should be inserted */
  placeholder: PropTypes.string,

  /** Specifies whether the input-field is required. */
  required: PropTypes.bool,

  /** Type of input-field, i.e 'text', 'number'. */
  type: PropTypes.oneOf([
    'color',
    'date',
    'datetime-local',
    'email',
    'month',
    'number',
    'password',
    'search',
    'tel',
    'text',
    'time',
    'url',
    'week'
  ]),

  /** A unit-label that is placed behind the input-field. */
  unit: PropTypes.string,

  /** The validity of the input-field. A state should be specified by the parent component. */
  valid: PropTypes.oneOf([true, false, null]),

  /** The current value of the input-field.  A state should be specified by the parent component. */
  value: PropTypes.any.isRequired,

  /** Set focus in the input field. */
  focus: PropTypes.bool
};

export default Input;
