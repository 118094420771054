const en = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
const no = ['S', 'M', 'T', 'O', 'T', 'F', 'L'];

const lang = {
  'en-UK': en,
  'en-US': en,
  'nb-NO': no
};

export default (locale, date) => {
  const short = date.toLocaleDateString(locale, { weekday: 'narrow' });

  if (short.length === 1) {
    return short;
  }
  const index = lang.hasOwnProperty(locale) ? locale : 'nb-NO';
  return lang[index][date.getDay()];
};
