import styled from 'styled-components';
import PropTypes from 'prop-types';

import Colors from '../../../styles/colors';

const backgroundColor = (disabled, checked) => {
  if (disabled) {
    return checked ? Colors.gray : Colors.lightestGray;
  }
  return checked ? Colors.positive : Colors.white;
};

const borderColor = (disabled, checked) => {
  if (disabled) {
    return Colors.gray;
  }
  return checked ? Colors.positive : Colors.darkGray;
};

const StyledToggle = styled.div`
  display: flex;
  flex: 1;
  line-height: 1.5rem;
  box-sizing: border-box;
  position: relative;

  input {
    position: absolute;
    top: 0.1875rem;
    left: 0.1875rem;
  }

  label {
    position: relative;
    display: flex;
    padding-left: ${(props) => (props.labelTextHidden ? '0.5rem' : '2rem')};
    padding-right: ${(props) => (props.labelTextHidden ? '0rem' : '2rem')};
    cursor: pointer;
    min-height: 1.5rem;
    color: ${Colors.white};
  }

  label:before,
  label:after {
    content: '';
    display: inline-block;
    position: absolute;
  }

  label:before {
    height: 1.3125rem;
    width: 1.3125rem;
    border: thin solid ${(props) => borderColor(props.disabled, props.checked)};
    border-radius: 0.375rem;
    left: 0;
    top: 0;
    background-color: ${(props) =>
      backgroundColor(props.disabled, props.checked)};
    transition: box-shadow 0.2s ease-out;
  }

  svg {
    position: absolute;
    top: 0.2rem;
    left: 0.25rem;
    width: 0.9375rem;
    height: 0.9375rem;
    color: ${Colors.white};
  }

  input + label {
    svg {
      opacity: 0;
    }
  }

  input:focus + label:before {
    outline: none;
    box-shadow: 0 0 5px 3px ${Colors.positiveShadeLight};
    transition: box-shadow 0.2s ease-out;
    border-color: ${Colors.positiveShadeLight};
  }

  input:checked + label {
    svg {
      opacity: 1;
    }
  }
`;

StyledToggle.propTypes = {
  disabled: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  labelTextHidden: PropTypes.bool.isRequired
};

export default StyledToggle;
