import React from 'react';
import PropTypes from 'prop-types';

import Back from './back.svg';
import IconWrapper from '../icon-wrapper';

export default function BackIcon({ scale, altText }) {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      <Back />
    </IconWrapper>
  );
}

BackIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string
};

BackIcon.defaultProps = {
  scale: 1
};
