import styled from 'styled-components';
import Colors from '../../../styles/colors';

const iconColors = {
  default: {
    content: Colors.white,
    border: Colors.black
  },
  green: {
    content: Colors.positiveShadeLight,
    border: Colors.black
  },
  blue: {
    content: Colors.secondaryShadeLight,
    border: Colors.black
  },
  yellow: {
    content: Colors.attentionShadeLighter,
    border: Colors.black
  },
  pink: {
    content: Colors.negativeShadeLightest,
    border: Colors.black
  }
};

const getStyle = (colorScheme) => {
  return {
    content: iconColors[colorScheme].content,
    border: iconColors[colorScheme].border
  };
};

export const RoundbaleIconStyle = styled.div`
  svg {
    #fill {
      fill: ${(props) =>
        props.colorScheme && getStyle(props.colorScheme).content};
    }

    #shape {
      fill: ${(props) =>
        props.colorScheme && getStyle(props.colorScheme).border};
    }
  }
`;
