import React from 'react';
import { StyledGridContainer } from './styled-components';

/**
 * We use CSS Grid for page layout.
 *
 * IE11:
 * CSS Grid has a fearly good support in IE11. We use Autoprefixer to compile our CSS to make it IE-compatible, but there is some features that lacks support and must be avoided.
 *
 *
 * AUTO-PLACEMENT
 *
 * One of the main things IE doesn´t support is auto-placement. Every cell must be placed manually.
 * When you need a grid that uses auto-placement, you can still use CSS Grid for modern browsers width the @supports statement.
 *
 * NOTE when compiled width Autoprefixer:
 *
 * //Checking grid-auto-* support prevents prefixes
 *
 * - @supports  (grid-auto-rows: 0) {
 *  .modern .grid {
 *     display: grid;
 *   }
 * }
 *
 * // Checking basic grid support still outputs prefixes
 *
 * -   @supports (display: grid) {
 *   .still-prefixed .grid {
 *     display: -ms-grid;
 *     display: grid;
 *   }
 * }
 *
 * When using an auto-placing grid, you should supply a fallback for IE11. Here is one technique:
 * https://css-tricks.com/css-grid-in-ie-faking-an-auto-placement-grid-with-gaps/
 *
 *
 *
 * Appart from auto-placement and a few other things, IE11 does support most of CSS Grid, and with Autoprefixer taking care of making our code IE11 friendly you should be set :D
 *
 * TO LEARN MORE ABOUT IE11 SUPPORT:
 *
 * Here is a great article on using CSS grid in a IE11 compatible way:
 * https://css-tricks.com/css-grid-in-ie-css-grid-and-the-new-autoprefixer/
 */

export const CssGridLayout = (props) => {
  return <StyledGridContainer>{props.children}</StyledGridContainer>;
};
