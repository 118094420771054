import React from 'react';
import PropTypes from 'prop-types';

import Text from '../../../elements/typography/text';

import Colors from '../../../styles/colors';

/**
 * The component is used for displaying short error messages.
 */

const ErrorText = ({ children }) => {
  return (
    <Text italic color={Colors.negative}>
      {children}
    </Text>
  );
};

ErrorText.propTypes = {
  children: PropTypes.string
};

export default ErrorText;
