import React from 'react';
import PropTypes from 'prop-types';

import Add from './add.svg';
import IconWrapper from '../icon-wrapper';

/**
 * [See current version in abstract]
 * (https://share.goabstract.com/50a15865-d03b-4439-ba61-1993c6bd1e60)
 */

export default function AddIcon({ scale, altText }) {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      <Add />
    </IconWrapper>
  );
}

AddIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string
};

AddIcon.defaultProps = {
  scale: 1
};
