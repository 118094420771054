import React from 'react';
import PropTypes from 'prop-types';
import ReactCalendar from 'react-calendar';
import StyledCalendarWrapper from './styled-calendar-wrapper';
import TriangleLeftIcon from '../../../elements/icons/triangle-left-icon';
import TriangleRightIcon from '../../../elements/icons/triangle-right-icon';

import capitalize from './util/capitalize.js';
import formatReallyShortWeekday from './util/format-really-short-weekday';

const nextLabel = <TriangleRightIcon scale={0.3} />;
const prevLabel = <TriangleLeftIcon scale={0.3} />;

/**
 * [See current version in abstract]
 * (https://share.goabstract.com/42480e6a-2b19-479c-a13f-9edcc0fa5d8b)
 * The Calendar component displays a calendar.
 */
export default function Calendar({
  onChange,
  locale,
  maxDate,
  minDate,
  value
}) {
  return (
    <StyledCalendarWrapper>
      <ReactCalendar
        locale={locale}
        formatShortWeekday={formatReallyShortWeekday}
        nextLabel={nextLabel}
        prevLabel={prevLabel}
        onChange={onChange}
        minDetail={'month'}
        maxDetail={'month'}
        navigationLabel={({ label }) => capitalize(label)}
        maxDate={maxDate}
        minDate={minDate}
        value={value}
        view={'month'}
      />
    </StyledCalendarWrapper>
  );
}

Calendar.propTypes = {
  /**
   * Override the default locale
   */
  locale: PropTypes.string,
  /**
   * Fires when a date has been selected
   */
  onChange: PropTypes.func,
  /**
   * Maximum value for selected date
   */
  maxDate: PropTypes.instanceOf(Date),
  /**
   * Minimum value for selected date
   */
  minDate: PropTypes.instanceOf(Date),

  /**
   * Value for selected date
   */
  value: PropTypes.instanceOf(Date)
};

Calendar.defaultProps = {
  locale: 'nb-NO'
};
