import styled from 'styled-components';
import Colors from '../../../styles/colors';

export const StyledEanaWebPageWrapper = styled.div`
  grid-area: page;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
  grid-template-areas:
    'header'
    'app'
    'footer';

  background-color: ${Colors.lightestGray};
  box-shadow: rgba(0, 0, 0, 0.03) 2px 0px 25px 0px;
`;
