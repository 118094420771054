import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import {
  TabMenu,
  TabMenuWrapper,
  TabScrollBar,
  TabScrollBarIndicator
} from './styled-components';
import ContentContainer from '../../alignment/content-container';
import TabMenuItem from './tab-menu-item';
import CalculateTextWidth from './calculate-text-width';

import { initialIndex, initialState, reducer } from './tab-bar-utils';
import { ANIMATE, RESIZE, TAB_INDEX, TRANSITION_END } from './action-types';
import { handleTabWidth, move, onMoveDone, scrollTo } from './actions';

/**
 * Simple component used for tab-based navigation.
 *
 * If the number of tabs overflows the parent element's size the list of tabs will become scrollable.
 * The function for rendering content will be called only when the tab is clicked.
 *
 * @param initialIndex initially selected index
 * @param onTabChange callback parent about tab changes
 * @param secondary indicates if tab bar should render smaller tabs
 * @param tabBarIdentifier identifier for key and data-cy
 * @param tabs array of tab titles
 * @param underlineActive whether to show or hide underline
 * @returns TabBar
 */
const TabBar = ({
  initialTab,
  onTabChange,
  secondary,
  tabBarIdentifier,
  tabs,
  underlineActive
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    tabIndex: initialIndex(tabs, initialTab),
    minTabWidth: secondary ? 70 : 200,
    defaultTabWidth: secondary ? 70 : 200,
    isFullWidth: tabs.length <= 3,
    tabsLength: tabs.length
  });

  const wrapperRef = useRef();
  const menuRef = useRef();

  const bindDispatch = (action) => {
    if (typeof action === 'function') {
      action(bindDispatch, state);
    } else {
      dispatch(action);
    }
  };

  const transitionEnd = () => {
    dispatch({ type: TRANSITION_END });
  };

  const measureSize = () => {
    const domNodeOuter = ReactDOM.findDOMNode(wrapperRef.current);
    const domNodeInner = ReactDOM.findDOMNode(menuRef.current);

    if (domNodeOuter && domNodeInner) {
      const outerRect = domNodeOuter.getBoundingClientRect();
      const innerRect = domNodeInner.getBoundingClientRect();
      dispatch({
        type: RESIZE,
        outerRect,
        innerRect
      });
    }
  };

  const optimizedMeasureSize = () => {
    if (window.requestAnimationFrame) {
      window.requestAnimationFrame(measureSize);
    } else {
      setTimeout(measureSize, 66);
    }
  };

  useEffect(() => {
    optimizedMeasureSize();
    window.addEventListener('resize', optimizedMeasureSize);
    const domNode = ReactDOM.findDOMNode(menuRef.current);
    domNode.addEventListener('transitionend', transitionEnd);
    return () => {
      domNode.removeEventListener('transitionend', transitionEnd);
      window.removeEventListener('resize', optimizedMeasureSize);
    };
  }, []);

  useEffect(() => {
    optimizedMeasureSize();
  }, [state.minTabWidth]);

  const onTabMenuClick = (index, key, rect) => {
    dispatch({ type: ANIMATE, animate: true });
    dispatch({ type: TAB_INDEX, tabIndex: index });
    bindDispatch(scrollTo(index, rect));
    if (onTabChange) {
      onTabChange(index, key);
    }
  };

  return (
    <Fragment>
      <ContentContainer
        styling={{
          backgroundColor: 'contentLighterGray',
          marginBottom: 'lowest'
        }}
      >
        <TabMenuWrapper
          data-cy={tabBarIdentifier}
          fullWidth={state.isFullWidth}
          key={`tab-bar-${tabBarIdentifier}`}
          ref={wrapperRef}
        >
          <TabMenu
            leftOffset={state.offset}
            isFullWidth={state.isFullWidth}
            ref={menuRef}
            animate={state.animate}
          >
            {tabs.map(({ title, key, disabled }, index) => (
              <TabMenuItem
                selected={state.tabIndex === index}
                underlineActive={underlineActive}
                isFullWidth={state.isFullWidth}
                minTabWidth={state.minTabWidth}
                tabPadding={state.tabPadding}
                onTabMenuClick={(rect) => onTabMenuClick(index, key, rect)}
                onMove={(moveX) => bindDispatch(move(moveX))}
                onMoveDone={() => dispatch(onMoveDone())}
                index={index}
                tabKey={key}
                disabled={disabled}
                key={`tab-menu-item-${key}`}
              >
                {title}
                <CalculateTextWidth
                  text={title}
                  onHandleTabWidth={(width) =>
                    dispatch(handleTabWidth(width, index))
                  }
                />
              </TabMenuItem>
            ))}
          </TabMenu>
        </TabMenuWrapper>
      </ContentContainer>
      {(state.moving || state.animate) && (
        <TabScrollBar>
          <TabScrollBarIndicator
            indicatorWidth={state.scrollBarIndicatorWidth}
            indicatorOffset={state.scrollBarIndicatorOffset}
          />
        </TabScrollBar>
      )}
    </Fragment>
  );
};

TabBar.defaultProps = {
  secondary: false,
  underlineActive: true
};

TabBar.propTypes = {
  /** Initially selected tab (matches key in tabs) */
  initialTab: PropTypes.string,
  /** Array of objects defining the tabs */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      disabled: PropTypes.bool
    })
  ).isRequired,
  /** Secondary TabBars have smaller tabs, for when there is a TabBars underneath another TabBars */
  secondary: PropTypes.bool,
  /** Underline active tab */
  underlineActive: PropTypes.bool,
  /** Callback to indicate tab has changed, is called with index */
  onTabChange: PropTypes.func,
  /** A unique identifier */
  tabBarIdentifier: PropTypes.string.isRequired
};

export default TabBar;
