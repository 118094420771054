import styled from 'styled-components';
import Colors from '../../../styles/colors';

const borderColor = (style) => {
  if (style === 'emphasized') {
    return Colors.positive;
  }
  return Colors.gray;
};

const expandableWidth = (expandable) => {
  return expandable ? 'calc(100% - 4rem)' : '100%';
};

export const ExpandingWrapper = styled.div`
  transition: height '250ms' ease-out;
  position: relative;
  padding-bottom: 0.625rem;
  min-width: 10rem;
  background-color: 'transparent';

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 2rem;
    height: 0.625rem;
    width: 0.125rem;
    background: ${Colors.darkGray};
    opacity: ${(props) => (props.withTimeline ? 1 : 0)};
  }

  &:last-of-type {
    padding-bottom: 0;
    &:after {
      content: none;
    }
  }
`;

export const ContentContainer = styled.div`
  overflow: hidden;
  position: relative;
  border: solid thin ${(props) => borderColor(props.borderStyle)};
  border-radius: 0.125rem;
  background-color: ${(props) => props.contentContainerBackgroundColor};
`;

export const CollapsedContent = styled.div`
  position: relative;

  cursor: pointer;
  border-bottom: solid thin ${Colors.lightGray};
  border-width: ${(props) => (props.contentBorder ? 'thin' : 0)};
`;

export const ExpandedContent = styled.div`
  opacity: 0;
  animation: fade-in-animation 0.5s linear forwards;

  @keyframes fade-in-animation {
    to {
      opacity: 1;
    }
  }
`;

export const ChildWrapper = styled.div``;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border-left: solid thin ${Colors.lightGray};
  border-width: ${(props) => (props.contentBorder ? 'thin' : 0)};

  &:active {
    fill: ${Colors.secondary};
  }

  &:hover {
    fill: ${Colors.secondary};
  }
`;

export const ArrowIconWrapper = styled.div`
  text-align: center;
  transform: rotate(${(props) => (props.expanded ? '-90deg' : '90deg')});
  transition: transform ${(props) => (props.expanded ? '250ms' : '100ms')}
    ease-out;
  height: 1rem;
  width: 4rem;
`;
