import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import StyledClickableIcon from '../common/styled-clickable-icon';

const StyledLink = StyledClickableIcon.withComponent(Link);

/**
 * The IconLink component wraps an Icon and provides a link.
 *
 * Any SVG provided in the icon should have a fill set to currentColor, not a specific color value.
 *
 */
export default function IconLink({ children, route }) {
  return <StyledLink to={route}>{children}</StyledLink>;
}

IconLink.propTypes = {
  /**
   * Route that the navigation should point to.
   *
   * Needs to be any cause the route could be both a string and an object
   * @see [Ref: react-router](https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/api/Link.md#to-object)
   */
  route: PropTypes.any.isRequired
};
