import styled from 'styled-components';
import Colors from '../../../styles/colors';

export const StyledBackground = styled.div`
  width: 100%;
  height: 100%;
  z-index: 9;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${Colors.blackMediumTransparency};
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transform: scale(${(props) => (props.show ? 1.0 : 1.1)});
  transition: visibility 0s linear ${(props) => (props.show ? 0 : 0.25)}s,
    opacity 0.25s 0s, transform 0.25s;
`;

export const StyledDialog = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  overflow: auto;
`;

export const GridLayout = styled.div`
  display: -ms-grid;
  display: grid;
  height: 100%;
  -ms-grid-columns: 1fr
    ${(props) => `minmax(${props.minWidth.web}, ${props.width.web})`} 1fr;
  grid-template-columns: 1fr ${(props) =>
      `minmax(${props.minWidth.web}, ${props.width.web})`} 1fr;
  -ms-grid-rows: 1fr ${(props) => props.height.web} 1fr;
  grid-template-rows: 1fr ${(props) => props.height.web} 1fr;
  grid-template-areas:
    '. . .'
    '. modal .'
    '. . .';

  .modal {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    grid-area: modal;
  }

  @media (max-width: 599px) {
    -ms-grid-columns: 1fr ${(props) => props.width.mobile} 1fr;
    grid-template-columns: 1fr ${(props) => props.width.mobile} 1fr;
    -ms-grid-rows: 1fr ${(props) => props.height.mobile} 1fr;
    grid-template-rows: 1fr ${(props) => props.height.mobile} 1fr;
  }

  @media (min-width: 600px) and (max-width: 1023px) {
    -ms-grid-columns: 1fr ${(props) => props.width.tablet} 1fr;
    grid-template-columns: 1fr ${(props) => props.width.tablet} 1fr;
    -ms-grid-rows: 1fr ${(props) => props.height.tablet} 1fr;
    grid-template-rows: 1fr ${(props) => props.height.tablet} 1fr;
  }
`;

export const StyledHeader = styled.div`
  position: fixed;
  width: ${(props) => `calc(${props.width.web} - 5rem)`};
  background-color: ${Colors.white};
  padding-top: 0.5rem;
  margin: 0rem 2.5rem 0;
  @media (max-width: 599px) {
    width: ${(props) => `calc(${props.width.mobile} - 5rem)`};
  }

  @media (min-width: 600px) and (max-width: 1023px) {
    width: ${(props) => `calc(${props.width.tablet} - 5rem)`};
  }
`;

export const StyledContent = styled.div`
  background-color: white;
  margin: 5rem 2.5rem 0;
`;

export const StyledClose = styled.span`
  color: #aaa;
  float: right;
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  role: button;

  :hover,
  :focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;
