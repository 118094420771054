import React from 'react';
import PropTypes from 'prop-types';
import { FlexGridWrapper, FlexGridSection } from './styled-components';
import SectionContainer from '../section-container';
import getStyle from './grid-types';
/**
 * The AutoplacementGridSection handles the layout of groups of components accross every device using an
 * auto-placement CSS Grid breaking them down on a new line as soon as the min-width is reached.
 * IE11 does not support auto-placement in CSS Grid, but there is a fallback Flex grid using media queries for setting number of columns.
 * Every item used in the grid needs to be wrapped with the FlexGridItem component in order for the fallback grid to work.
 *
 */

export default function AutoplacementGridSection({
  kpi,
  testResults,
  children,
  className
}) {
  //Sets default props and merges with custom set props. Custom props overrides default props set here.

  const styling = {
    mobileOffset: true,
    paddingTop: 'low',
    paddingBottom: 'high'
  };

  const style = getStyle(kpi, testResults);

  return (
    <SectionContainer className={className} styling={styling}>
      <FlexGridWrapper>
        <FlexGridSection style={style}>{children}</FlexGridSection>
      </FlexGridWrapper>
    </SectionContainer>
  );
}

/* This component is based on the SectionContainer component. See propTypes in SectionContainer to view available props */

AutoplacementGridSection.propTypes = {
  children: PropTypes.node,
  /* For css grid use we need to define a unique class name to be able to target and map it to the grid  */
  className: PropTypes.string,
  /**
   * If true it would set the grid to the kpi grid for use with KpiComponents.
   */
  kpi: PropTypes.bool,
  /**
   * If true it would set the grid to the testResults type for use with the test result component.
   */
  testResults: PropTypes.bool
};
