import React from 'react';
import PropTypes from 'prop-types';

import Graph from './graph.svg';
import IconWrapper from '../icon-wrapper';

/**
 * [See current version in abstract]
 * (https://share.goabstract.com/54a5551b-24e4-4a7e-8a75-b577d5c785f0)
 */

export default function GraphIcon({ scale, altText }) {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      <Graph />
    </IconWrapper>
  );
}

GraphIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string
};

GraphIcon.defaultProps = {
  scale: 1
};
