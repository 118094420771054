import React from 'react';
import PropTypes from 'prop-types';

import Carrot from './carrot.svg';
import IconWrapper from '../icon-wrapper';
import { CarrotIconStyle } from './styled-components';

/**
 * [See current version in abstract]
 * ()
 */

export default function CarrotIcon({ scale, altText, colorScheme }) {
  return (
    <IconWrapper width={0.75 * scale} height={2 * scale} aria-label={altText}>
      <CarrotIconStyle colorScheme={colorScheme}>
        <Carrot />
      </CarrotIconStyle>
    </IconWrapper>
  );
}

CarrotIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string,
  /**
   * Available color schemes. If not defined, the default SVG colors will be used.
   */
  colorScheme: PropTypes.oneOf(['default'])
};

CarrotIcon.defaultProps = {
  scale: 1,
  altText: 'carrot-icon',
  colorScheme: 'default'
};
