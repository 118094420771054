import React from 'react';
import StyledEanaWebGridLayout from './styled-components';

/**
 * The main grid layout wrapper.
 * Sets a CSS-grid for positioning of elements.
 */

export default function EanaWebGridLayout({ children }) {
  return <StyledEanaWebGridLayout>{children}</StyledEanaWebGridLayout>;
}
