import {
  ANIMATE,
  MOVE,
  MOVE_START,
  MOVE_STOP,
  RESIZE,
  TAB_INDEX,
  TAB_WIDTH,
  TRANSITION_END
} from './action-types';

const MOBIL_SCREEN_WIDTH = 800;
export const TAB_EXTRA_OFFSET = 35;

export const isSmallWidth = (rect, width = MOBIL_SCREEN_WIDTH) =>
  rect ? rect.width < width : false;

export const fitsWidth = (minWidth, tabWidths) => {
  for (let i = 0; i < Object.keys(tabWidths).length; i++) {
    if (tabWidths[i] > minWidth) {
      return false;
    }
  }
  return true;
};

export const totalTabWith = (tabWidths, stop = -1, padding = 0) => {
  let total = 0;
  const keys = Object.keys(tabWidths);
  for (let i = 0; i < keys.length; i++) {
    if (i === stop) {
      break;
    }
    total += tabWidths[i] + padding;
  }
  return total;
};

export const fitsMinWidth = (rect, tabsLength, tabWidths) => {
  const widthPerTab = (rect.width * (100 / tabsLength)) / 100;
  return tabsLength < 4 && fitsWidth(widthPerTab, tabWidths);
};

export const scrollOffset = (width, offset) => (width / 100) * Math.abs(offset);

export const calculateTabOffset = (
  prevOffset,
  moveX,
  innerRect,
  outerRect,
  dX = 0
) => {
  const innerWidth = innerRect.width;
  const outerWidth = outerRect.width;

  let offset = null;
  if (innerWidth >= outerWidth) {
    const tabOffset = -Math.abs(prevOffset) + moveX;
    if (tabOffset > 0) {
      offset = 0;
    } else if (tabOffset + innerWidth < outerWidth) {
      offset = outerWidth - innerWidth;
    } else {
      if (tabOffset + dX < 0 && 0 < tabOffset + innerWidth - outerWidth + dX) {
        offset = tabOffset + dX;
      } else {
        offset = tabOffset;
      }
    }
  }
  return offset;
};

export function reducer(state, action) {
  switch (action.type) {
    case ANIMATE:
      return {
        ...state,
        animate: action.animate
      };
    case MOVE:
      return {
        ...state,
        offset: action.offset,
        scrollBarIndicatorOffset: scrollOffset(
          state.scrollBarIndicatorWidth,
          action.offset
        )
      };
    case MOVE_START:
      return {
        ...state,
        moving: true
      };
    case MOVE_STOP:
      return {
        ...state,
        moving: false
      };
    case RESIZE:
      const hasSmallWidth = isSmallWidth(action.outerRect);
      const tabsFitWidth =
        hasSmallWidth &&
        fitsMinWidth(action.outerRect, state.tabsLength, state.tabWidths);
      const isFullWidth = hasSmallWidth && tabsFitWidth;

      let offset = 0;
      let tabPadding = '2rem';
      if (isFullWidth) {
        tabPadding =
          (action.outerRect.width - totalTabWith(state.tabWidths)) /
            (2 * 2 * state.tabsLength) +
          'px';
      } else {
        offset = calculateTabOffset(
          0,
          -totalTabWith(state.tabWidths, state.tabIndex, 64) + TAB_EXTRA_OFFSET,
          action.innerRect,
          action.outerRect
        );
      }

      const scrollBarIndicatorWidth =
        (action.outerRect.width / action.innerRect.width) * 100;
      return {
        ...state,
        offset,
        outerRect: action.outerRect,
        innerRect: action.innerRect,
        minTabWidth: !tabsFitWidth && state.defaultTabWidth,
        isFullWidth,
        tabPadding,
        scrollBarIndicatorWidth,
        scrollBarIndicatorOffset: scrollOffset(
          scrollBarIndicatorWidth,
          state.offset
        )
      };
    case TAB_INDEX:
      return {
        ...state,
        tabIndex: action.tabIndex
      };
    case TRANSITION_END:
      return {
        ...state,
        animate: false
      };
    case TAB_WIDTH:
      return {
        ...state,
        scrollBarIndicatorOffset: scrollOffset(
          state.scrollBarIndicatorWidth,
          state.offset
        ),
        tabWidths: {
          ...state.tabWidths,
          [action.index]: action.width
        }
      };
    default:
      throw new Error();
  }
}

export const initialIndex = (tabs, initialTab = '') => {
  for (let i = 0; i < tabs.length; i++) {
    if (tabs[i].key === initialTab) {
      return i;
    }
  }
  return 0;
};

export const initialState = {
  outerRect: null,
  innerRect: null,
  offset: 0,
  animate: false,
  minTabWidth: 200,
  tabWidths: {},
  isFullWidth: false,
  scrollBarIndicatorWidth: 100,
  scrollBarIndicatorOffset: 0,
  tabPadding: '2rem'
};
