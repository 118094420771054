import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../../../styles/colors';
import StyledNumeral from './styled-numeral';

/**
 * Numeral component is the prefered component for numbers.
 *
 * It is using the PT Serif font and has option for tree different sizes [small, normal and large].
 *
 * All the colors from the colorpalette is possible to use, but the default is black.
 */
export default function Numeral({ children, size, color }) {
  return (
    <StyledNumeral size={Numeral.sizes[size]} color={color}>
      {children}
    </StyledNumeral>
  );
}

Numeral.propTypes = {
  /** Value to show */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]).isRequired,
  /** Choose the size: */
  size: PropTypes.oneOf(['small', 'normal', 'large', 'extralarge']),
  /** Choose what color you want by using the colors from the color palette. */
  color: PropTypes.string
};

Numeral.defaultProps = {
  size: 'normal',
  color: Colors.black
};

Numeral.sizes = {
  small: '1rem',
  normal: '1.625rem',
  large: '3rem',
  extralarge: '3.4375rem'
};
