import styled from 'styled-components';
import Colors from '../../../styles/colors/colors';
import Typography from '../../../styles/typography';

export const TabMenuWrapper = styled.div`
  position: relative;
  height: 3.125rem;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
`;

export const TabMenu = styled.div.attrs((props) => ({
  style: {
    left: `${props.leftOffset}px`
  }
}))`
  position: absolute;
  white-space: nowrap;
  height: 3rem;
  background-color: ${Colors.white};
  ${(props) =>
    props.isFullWidth &&
    `
    display: flex;
    flex-direction: row;
    min-width: 100%;
  `};
  ${(props) => props.animate && 'transition: left 0.25s ease-out;'};
`;

const backgroundColor = (props) => {
  if (props.disabled) {
    return Colors.lightGray;
  } else if (props.selected) {
    return Colors.white;
  }
  return Colors.lightestGray;
};

export const StyledTabMenuItem = styled.a`
  display: inline-block;
  background-color: ${backgroundColor};
  border: thin solid ${Colors.gray};
  ${(props) => props.selected && `border-bottom: thin solid ${Colors.white}`};
  border-collapse: collapse;
  padding: 0 ${({ tabPadding }) => tabPadding};
  flex-grow: 1;
  height: 100%;
  font-family: ${Typography.text.name};
  color: ${Colors.black};
  font-style: normal;
  font-weight: ${(props) =>
    props.selected ? Typography.text.bold : Typography.text.normal};
  font-size: ${Typography.text.sizes.normal};
  text-align: center;
  user-select: none;

  &:hover {
    background-color: ${(props) =>
      props.disabled ? Colors.lightGray : Colors.white};
  }

  ${({ isFullWidth, minTabWidth }) =>
    !(isFullWidth && minTabWidth) && `min-width: ${minTabWidth - 32}px`};
`;

export const StyledTabInnerContainer = styled.div`
  height: 100%;
  padding: 1rem;

  ${(props) =>
    props.selected &&
    props.underlineActive &&
    `border-bottom: .2rem solid ${Colors.secondary}`};
  box-sizing: border-box;
  border-collapse: collapse;
`;

export const TabScrollBar = styled.div`
  max-width: ${(props) => `${props.barWidth}px`};
  position: relative;
  width: 100%;
  white-space: nowrap;
  height: 0.25rem;
  background-color: ${Colors.lightGray};
  overflow: hidden;
  margin-top: -0.25rem;
`;

export const TabScrollBarIndicator = styled.div.attrs((props) => ({
  style: {
    width: `${props.indicatorWidth}%`,
    left: `${props.indicatorOffset}px`
  }
}))`
  position: absolute;
  white-space: nowrap;
  height: 0.25rem;
  background-color: ${Colors.gray};
`;
