import styled from 'styled-components';

export const StyledEanaWebGridLayout = styled.div`
    max-width: 100rem;
    min-height: 100vh;

    display:grid;
    grid-template-columns: 15rem 1fr;
    grid-template-rows: min-content min-content 1fr;
    grid-template-areas:
    'menuLine menuLine'
    'consultant consultant'
    'menu page';
@media (max-width: 1000px) {
    grid-template-columns: 0rem 1fr;
    }
  }
`;

export default StyledEanaWebGridLayout;
