import React from 'react';
import PropTypes from 'prop-types';

import Mimiro from './mimiro.svg';
import IconWrapper from '../icon-wrapper';
import { MimiroIconStyle } from './styled-mimiro-icon';

export default function MimiroIcon({ scale, altText, colorScheme }) {
  return (
    <IconWrapper
      width={3.25 * scale}
      height={0.9375 * scale}
      aria-label={altText}
    >
      <MimiroIconStyle colorScheme={colorScheme}>
        <Mimiro />
      </MimiroIconStyle>
    </IconWrapper>
  );
}

MimiroIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string,
  /**
   * Available color schemes. If not defined, the original SVG colors will be used.
   */
  colorScheme: PropTypes.oneOf(['white', 'black', 'negative'])
};

MimiroIcon.defaultProps = {
  scale: 1
};
