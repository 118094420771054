import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import StyledClickableButton from '../common/styled-clickable-button';
import getStyle from '../common/button-styles';
import disabledLink from '../common/disabled-link';

const StyledLink = StyledClickableButton.withComponent(disabledLink(Link));

/**
 * [See current version in abstract]
 * (https://app.goabstract.com/projects/86166c10-b73b-11e8-b61c-bbf42f55e4f3/branches/master/collections/c79c1e3f-dac3-474e-8c0c-c6612cd5defd)
 *
 * The Link component, styled as a button. When you need a button to perform navigation.
 *
 * It comes in four different variations primary, secondary, tertiary and multi where primary is default.
 * It also has a disabled property.
 */
export default function NavigationButton({
  children,
  secondary,
  tertiary,
  multi,
  disabled = false,
  route
}) {
  const style = getStyle(secondary, tertiary, multi);

  return (
    <StyledLink
      data-cy="navigation-button"
      to={route}
      disabled={disabled}
      aria-disabled={disabled}
      style={style}
    >
      {children}
    </StyledLink>
  );
}

NavigationButton.propTypes = {
  /** Text to show in button. */
  children: PropTypes.string.isRequired,
  /**
   * If true it would override the default style primary with the secondary style.
   */
  secondary: PropTypes.bool,
  /**
   * If true it would override the default style primary with the tertiary style.
   */
  tertiary: PropTypes.bool,
  /**
   * If true it would override the default style primary with the multi style.
   */
  multi: PropTypes.bool,
  /**
   * The route the link should point to.
   */
  route: PropTypes.any.isRequired,
  /**
   * If the button should be disabled
   */
  disabled: PropTypes.bool
};
