import styled from 'styled-components';
import Typography from '../../../styles/typography';

const StyledNumeral = styled.span`
  font-family: ${Typography.number.name};
  font-size: ${(props) => props.size};
  font-weight: ${Typography.number.bold};
  color: ${(props) => props.color};
`;

export default StyledNumeral;
