import React from 'react';
import PropTypes from 'prop-types';
import Numeral from '../../../elements/typography/numeral';
import EmphasisLight from '../../../elements/typography/text/emphasis-light';
import Text from '../../../elements/typography/text';
import ActionButton from '../../buttons/action-button';
import Colors from '../../../styles/colors';
import {
  ErrorMessageWrapper,
  MessageWrapper,
  TextWrapper,
  ButtonWrapper
} from './styled-components';

/**
 * [See current version in abstract]
 * (https://share.goabstract.com/33d975d4-0262-4218-8ff8-ca1873270159)
 *
 * The ErrorMessage is meant as a generic error component when unable to show information.
 */
const ErrorMessage = ({
  errorHeader,
  title,
  message,
  messageColor,
  icon,
  buttonConfig,
  datacy
}) => {
  return (
    <ErrorMessageWrapper data-cy={datacy}>
      <MessageWrapper>
        {errorHeader && <Numeral color={Colors.gray}>{errorHeader}</Numeral>}
        <TextWrapper>
          <EmphasisLight size="large">{title}</EmphasisLight>
          <Text
            italic
            color={messageColor === 'error' ? Colors.negative : Colors.black}
          >
            {message}
          </Text>
        </TextWrapper>
        {icon}
        {buttonConfig && (
          <ButtonWrapper>
            <ActionButton secondary onClick={() => buttonConfig.onClick()}>
              {buttonConfig.text}
            </ActionButton>
          </ButtonWrapper>
        )}
      </MessageWrapper>
    </ErrorMessageWrapper>
  );
};

ErrorMessage.defaultProps = {
  messageColor: 'error'
};

ErrorMessage.propTypes = {
  /**
   * A header for the error message
   */
  errorHeader: PropTypes.string,
  /**
   * A title of the message
   */
  title: PropTypes.string,
  /**
   * A message about what went wrong
   */
  message: PropTypes.string.isRequired,
  /**
   * Color for the message
   */
  messageColor: PropTypes.oneOf(['error', 'info']),
  /**
   * An Icon component
   */
  icon: PropTypes.node,
  /**
   * Config for error message button
   */
  buttonConfig: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  })
};

export default ErrorMessage;
