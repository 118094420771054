import React from 'react';
import PropTypes from 'prop-types';

import StyledToggle from './styled-toggle';
import CheckIcon from '../../../elements/icons/check-icon';
import Text from '../../../elements/typography/text/text';

/**
 * [See current version in abstract]
 * (https://app.goabstract.com/projects/86166c10-b73b-11e8-b61c-bbf42f55e4f3/branches/master/collections/9876e8f8-e9cc-453d-89c8-719b79216a11/presentation)
 *
 * CheckboxButton behaves like a checkbox, and is used for indicating that something is selected or not selected.
 *
 * It can be selected or deselected. It can also be disabled to avoid user interaction.
 */
export default function CheckboxButton({
  id,
  onChange,
  checked,
  disabled,
  labelText,
  labelTextHidden
}) {
  return (
    <StyledToggle
      disabled={disabled}
      checked={checked}
      labelTextHidden={labelTextHidden}
    >
      <input
        data-cy="checkbox-button"
        type="checkbox"
        id={id}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        aria-label={labelText}
      />
      <label htmlFor={id}>
        {!labelTextHidden && <Text>{labelText}</Text>}
        <CheckIcon scale={0.5} />
      </label>
    </StyledToggle>
  );
}

CheckboxButton.propTypes = {
  /**
   * Unique identifier for the checkbox
   */
  id: PropTypes.string.isRequired,
  /**
   * Handler to be called when the value of the checkmark is changed.
   */
  onChange: PropTypes.func,
  /**
   * If true the checkmark is selected
   */
  checked: PropTypes.bool.isRequired,
  /**
   * The text label of the checkmark. This is also used as aria-label for accessibility.
   */
  labelText: PropTypes.string.isRequired,
  /**
   * Boolean indicating if the label text should be hidden
   */
  labelTextHidden: PropTypes.bool,
  /**
   * If true the checkmark is disabled, and onToggle will not be called on click.
   */
  disabled: PropTypes.bool
};

CheckboxButton.defaultProps = {
  disabled: false,
  labelTextHidden: false
};
