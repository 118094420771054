import styled from 'styled-components';
import {
  AlertIconStyle,
  AlertIconNeutral,
  AlertIconNegative
} from '../../../elements/icons/alert-icon/styled-alert-icon';

const ConfirmDialogImageWrapper = styled.div`
  margin-top: 1rem;
  ${AlertIconStyle} {
    ${(props) => (props.warning ? AlertIconNegative : AlertIconNeutral)};
  }
`;

export default ConfirmDialogImageWrapper;
