import styled from 'styled-components';
import Colors from '../../../styles/colors';

const iconColors = {
  default: {
    content: 'none',
    border: Colors.black
  }
};

const getStyle = (colorScheme) => {
  return {
    content: iconColors[colorScheme].content,
    border: iconColors[colorScheme].border
  };
};

export const CornIconStyle = styled.div`
  svg {
    #fill {
      fill: ${(props) =>
        props.colorScheme && getStyle(props.colorScheme).content};
    }

    #shape {
      fill: ${(props) =>
        props.colorScheme && getStyle(props.colorScheme).border};
    }
  }
`;
