import {
  ANIMATE,
  MOVE,
  MOVE_START,
  MOVE_STOP,
  TAB_WIDTH
} from './action-types';

import { calculateTabOffset, TAB_EXTRA_OFFSET } from './tab-bar-utils';

export const onMoveDone = () => ({ type: MOVE_STOP });

export const handleTabWidth = (width, index) => ({
  type: TAB_WIDTH,
  index,
  width
});

export const move = (moveX, isDrag = true, dX = 0) => {
  return (dispatch, state) => {
    if (isDrag && !state.moving) {
      dispatch({ type: MOVE_START });
    }
    const offset = calculateTabOffset(
      state.offset,
      moveX,
      state.innerRect,
      state.outerRect,
      dX
    );
    if (offset !== null) {
      dispatch({ type: MOVE, offset });
    }
  };
};

export const scrollTo = (index, rect) => {
  return (dispatch, state) => {
    if (rect && state.outerRect && rect.x < state.outerRect.x) {
      dispatch(move(state.outerRect.x - rect.x, false, TAB_EXTRA_OFFSET));
    } else if (
      rect &&
      state.outerRect &&
      Math.round(rect.x + rect.width) >
        state.outerRect.x + state.outerRect.width
    ) {
      dispatch(
        move(
          state.outerRect.x + state.outerRect.width - rect.x - rect.width,
          false,
          -TAB_EXTRA_OFFSET
        )
      );
    } else {
      dispatch({ type: ANIMATE, animate: false });
    }
  };
};
