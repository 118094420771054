import React from 'react';
import { StyledEanaWebBody } from './styled-components';

/**
 * Wrapper component used in the apps as a wrapper around the body for the Eana Web Portal.
 *
 */

export default function EanaWebBody({ children }) {
  return <StyledEanaWebBody>{children}</StyledEanaWebBody>;
}
