import React from 'react';
import PropTypes from 'prop-types';

import FadeBackgroundWrapper from './fade-background-wrapper';
import ConfirmDialogWrapper from './confirm-dialog-wrapper';
import ImageWrapper from './confirm-dialog-image-wrapper';
import TitleWrapper from './confirm-dialog-title-wrapper';
import MessageWrapper from './confirm-dialog-message-wrapper';
import ButtonsWrapper from './confirm-dialog-buttons-wrapper';
import ActionButton from '../../buttons/action-button';
import AlertIcon from '../../../elements/icons/alert-icon';
import Emphasis from '../../../elements/typography/text/emphasis';
import Text from '../../../elements/typography/text';

/**
 * [See current version in abstract]
 * (https://share.goabstract.com/dd4ec3fe-eb82-4ae0-822a-5099ee4edbd1)
 *
 * Simple confirm dialog component used to display information to the user and prompts for a response.
 *
 * The component take props for the title and the optional message that should be displayed in the dialog.
 *
 * It is also possible to send up to two button configs that would add the cancel and confirm buttons.
 */
const ConfirmDialog = ({
  cancelText,
  confirmText,
  iconNode,
  message,
  onCancel,
  onConfirm,
  title,
  warning,
  warningCancel
}) => {
  const unsafeMessage = { __html: message };
  return (
    <FadeBackgroundWrapper>
      <ConfirmDialogWrapper data-cy="confirm-dialog">
        <ImageWrapper warning={warning}>{iconNode}</ImageWrapper>
        <TitleWrapper>
          <Emphasis>{title}</Emphasis>
        </TitleWrapper>
        <MessageWrapper>
          <Text>
            <span dangerouslySetInnerHTML={unsafeMessage} />
          </Text>
        </MessageWrapper>
        <ButtonsWrapper>
          {cancelText && (
            <ActionButton
              multi={!warningCancel}
              warning={warningCancel}
              onClick={onCancel}
            >
              {cancelText}
            </ActionButton>
          )}
          {confirmText && (
            <ActionButton
              primary={!warning}
              warning={warning}
              onClick={onConfirm}
            >
              {confirmText}
            </ActionButton>
          )}
        </ButtonsWrapper>
      </ConfirmDialogWrapper>
    </FadeBackgroundWrapper>
  );
};
ConfirmDialog.defaultProps = {
  iconNode: <AlertIcon scale={1.5} />,
  warning: false,
  negativeCancel: false
};

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.node,
  warning: PropTypes.bool,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  iconNode: PropTypes.node,
  warningCancel: PropTypes.bool
};

export default ConfirmDialog;
