import React from 'react';
import PropTypes from 'prop-types';
import IconWrapper from '../icon-wrapper';
import Trashcan from './trashcan.svg';

export default function TrashcanIcon({ scale, altText }) {
  return (
    <IconWrapper width={1.2 * scale} height={1.3 * scale} aria-label={altText}>
      <Trashcan />
    </IconWrapper>
  );
}

TrashcanIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string
};

TrashcanIcon.defaultProps = {
  scale: 1
};
