import styled from 'styled-components';
import Colors from '../../../styles/colors';

const iconColors = {
  black: {
    text: Colors.black,
    icon: Colors.black
  },
  white: {
    text: Colors.white,
    icon: Colors.white
  },
  negative: {
    text: Colors.white
  }
};

const getStyle = (colorScheme) => {
  return {
    fillIcon: iconColors[colorScheme].icon,
    fillText: iconColors[colorScheme].text
  };
};

export const MimiroIconStyle = styled.div`
  svg {
    .mimiro-cls-3 {
      fill: ${(props) =>
        props.colorScheme && getStyle(props.colorScheme).fillIcon};
    }
    .mimiro-cls-2 {
      fill: ${(props) =>
        props.colorScheme && getStyle(props.colorScheme).fillText};
    }
  }
`;
