import React from 'react';
import PropTypes from 'prop-types';

import { AlertIcon, CheckMarkIcon } from '../../../../elements/icons';

import {
  ICON_SCALE,
  StyledInputValidationIconWrapper
} from './styled-components';

const ValidationIcon = ({ disabled, valid }) => {
  function renderValidationIcon(disabled, valid) {
    if (disabled || valid === null) {
      return null;
    }
    const Icon = valid ? CheckMarkIcon : AlertIcon;
    return (
      <StyledInputValidationIconWrapper>
        <Icon scale={ICON_SCALE} colorScheme={!valid && 'negative'} />
      </StyledInputValidationIconWrapper>
    );
  }

  return renderValidationIcon(disabled, valid);
};

ValidationIcon.propTypes = {
  //** A boolean to distinguish wheather it is a label applied to note input or common text input */
  noteInput: PropTypes.bool,
  /** A boolean to disable/enable validation icon */
  disabled: PropTypes.bool,
  /** A boolean to render different style based on status of the input field*/
  valid: PropTypes.bool
};

export default ValidationIcon;
