import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import {
  ContentContainer,
  CollapsedContent,
  ExpandedContent,
  ExpandingWrapper,
  ButtonWrapper,
  ChildWrapper
} from './styled-expanding-container';
import Colors from '../../../styles/colors';
import ArrowButton from './arrow-button';

/**
 * The expanding container will enlarge and show additional content on click.
 * The collapsed and expanded content is passed to the component from the parent,
 * and is expected to be complete with regards to styling and layout.
 *
 * The component can be passed a function that allows the parent component to keep track of the
 * current state of the expanding container. This will allow the component to receive different props based on state.
 *
 * Bottom-padding in the collapsed content will create a separation between contents. If this separation is undesirable,
 * the expanded content wrapper should have a negative margin equal to the bottom padding of the collapsed content.
 *
 * If the size of the container changes at some time other than when expanding or collapsing, please dispatch the
 * custom event `updateAccordionDimensions`.
 */

const ExpandingContainer = ({
  borderStyle,
  collapsedContent,
  contentBorder,
  contentContainerBackgroundColor,
  expandable,
  expanded,
  expandedContent,
  stateUpdate,
  withTimeline
}) => {
  const contentContainer = createRef();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (stateUpdate) {
      stateUpdate(isExpanded);
    }
  }, [isExpanded]);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  function toggleState() {
    setIsExpanded(!isExpanded);
  }

  function renderCollapsedContent() {
    return (
      <CollapsedContent
        onClick={() => !contentBorder && expandable && toggleState()}
        contentBorder={contentBorder}
      >
        <ChildWrapper expandable={expandable}>{collapsedContent}</ChildWrapper>

        {expandable && (
          <ButtonWrapper
            data-cy={isExpanded ? 'chevron-up' : 'chevron-down'}
            contentBorder={contentBorder}
            onClick={() => {
              contentBorder && toggleState();
            }}
          >
            <ArrowButton expanded={isExpanded} expandable={expandable} />
          </ButtonWrapper>
        )}
      </CollapsedContent>
    );
  }

  return (
    <ExpandingWrapper
      data-cy={`expanding-container${isExpanded ? '-expanded' : ''}`}
      expanded={isExpanded}
      withTimeline={withTimeline}
    >
      <ContentContainer
        ref={contentContainer}
        borderStyle={borderStyle}
        contentContainerBackgroundColor={contentContainerBackgroundColor}
      >
        {renderCollapsedContent()}
        {isExpanded && <ExpandedContent>{expandedContent}</ExpandedContent>}
      </ContentContainer>
    </ExpandingWrapper>
  );
};

ExpandingContainer.propTypes = {
  /**
   * Boolean indicating if a gray timeline should be shown
   * between the containers.
   */
  withTimeline: PropTypes.bool,
  /**
   * The collapsed content
   */
  collapsedContent: PropTypes.node,
  /**
   * The expanded content
   */
  expandedContent: PropTypes.node,
  /**
   * An function called when the container expands or collapses.
   * @argument { bool } expanded
   */
  stateUpdate: PropTypes.func,
  /**
   * An enum indicating if the border should be emphasized
   */
  borderStyle: PropTypes.oneOf(['default', 'emphasized']),
  /**
   * Boolean indicating if the content should be seperated from the arrow button with borders.
   * The click area for toggling the expanding and collapsing will be restricted to
   * the view containing the arrow button.
   */
  contentBorder: PropTypes.bool,
  /**
   * Boolean indicating if the container can be expanded or not.
   */
  expandable: PropTypes.bool,
  /**
   * Boolean indicating if the container should be expanded by default.
   */
  expanded: PropTypes.bool,
  /**
   * A hex-code that sets the background-color for the content
   */
  contentContainerBackgroundColor: PropTypes.string
};

ExpandingContainer.defaultProps = {
  borderStyle: 'default',
  withTimeline: true,
  contentBorder: false,
  expandable: true,
  contentContainerBackgroundColor: Colors.white
};

export default ExpandingContainer;
