import React from 'react';
import PropTypes from 'prop-types';

import ConcentratesSilo from './concentrates-silo.svg';
import IconWrapper from '../icon-wrapper';
import { ConcentratesSiloIconStyle } from './styled-components';

/**
 * [See current version in abstract]
 * ()
 */

export default function ConcentratesSiloIcon({ scale, altText, colorScheme }) {
  return (
    <IconWrapper width={1.25 * scale} height={2 * scale} aria-label={altText}>
      <ConcentratesSiloIconStyle colorScheme={colorScheme}>
        <ConcentratesSilo />
      </ConcentratesSiloIconStyle>
    </IconWrapper>
  );
}

ConcentratesSiloIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string,
  /**
   * Available color schemes. If not defined, the default SVG colors will be used.
   */
  colorScheme: PropTypes.oneOf(['default'])
};

ConcentratesSiloIcon.defaultProps = {
  scale: 1,
  altText: 'concentrates-silo-icon',
  colorScheme: 'default'
};
