import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import {
  StyledTabInnerContainer,
  StyledTabMenuItem
} from './styled-components';

const hasTouches = (event) =>
  event.targetTouches && event.targetTouches.length > 0;

const xTouch = (event) => event.targetTouches[0].screenX;

const xTouchPosition = (event, fallback) =>
  hasTouches(event) ? xTouch(event) : fallback;

const TabMenuItem = ({
  children,
  disabled,
  isFullWidth,
  minTabWidth,
  onMove,
  onMoveDone,
  onTabMenuClick,
  selected,
  tabKey,
  tabPadding,
  underlineActive
}) => {
  const [active, setActive] = useState(false);
  const [moving, setMoving] = useState(false);
  const [startPosition, setStartPosition] = useState(0);
  const [prevTouchPosition, setTouchPosition] = useState(0);
  const ref = useRef();

  const xPosition = (event) =>
    event.targetTouches
      ? xTouchPosition(event, prevTouchPosition)
      : event.screenX;

  const dX = (event) => Math.abs(xPosition(event) - startPosition);

  const onMoveEnd = () => {
    setActive(false);
    setMoving(false);
    setTouchPosition(0);
    onMoveDone();
  };

  const onClick = (event) => {
    event.preventDefault();
    if (!disabled && (!moving || dX(event) < 3)) {
      const domNode = ReactDOM.findDOMNode(ref.current);
      let rect = null;
      if (domNode) {
        rect = domNode.getBoundingClientRect();
      }
      onTabMenuClick(rect);
    }
    onMoveEnd();
  };

  const onTouchMove = (event) => {
    if (active) {
      setMoving(true);
      const touchPosition = xTouch(event);
      onMove(touchPosition - prevTouchPosition);
      setTouchPosition(touchPosition);
    }
  };

  const onMouseMove = (event) => {
    if (active) {
      setMoving(true);
      const mouseButtonPressed = event.buttons === 1;
      if (!mouseButtonPressed) {
        return false;
      }
      event.preventDefault();
      onMove(event.movementX);
    }
    return false;
  };

  const onStartMove = (event) => {
    if (!isFullWidth) {
      setActive(true);
      setStartPosition(xPosition(event));
    }
    if (hasTouches(event)) {
      setTouchPosition(xTouch(event));
    }
  };

  return (
    <StyledTabMenuItem
      onMouseDown={onStartMove}
      onMouseMove={onMouseMove}
      onMouseLeave={onMoveEnd}
      onMouseUp={onClick}
      onTouchStart={onStartMove}
      onTouchMove={onTouchMove}
      onTouchEnd={onClick}
      disabled={disabled}
      ref={ref}
      selected={selected}
      isFullWidth={isFullWidth}
      minTabWidth={minTabWidth}
      tabPadding={tabPadding}
      data-cy={`item-${tabKey}`}
    >
      <StyledTabInnerContainer
        selected={selected}
        underlineActive={underlineActive}
      >
        {children}
      </StyledTabInnerContainer>
    </StyledTabMenuItem>
  );
};

TabMenuItem.defaultProps = {
  disabled: false
};

TabMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  isFullWidth: PropTypes.bool.isRequired,
  minTabWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onMove: PropTypes.func.isRequired,
  onMoveDone: PropTypes.func.isRequired,
  onTabMenuClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  tabKey: PropTypes.string.isRequired,
  tabPadding: PropTypes.string.isRequired,
  underlineActive: PropTypes.bool.isRequired
};

export default TabMenuItem;
