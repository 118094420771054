import React from 'react';
import PropTypes from 'prop-types';

import Kpi from './kpi.svg';
import IconWrapper from '../icon-wrapper';

/**
 * [See current version in abstract]
 * (https://share.goabstract.com/54a5551b-24e4-4a7e-8a75-b577d5c785f0)
 */

export default function KpiIcon({ scale, altText }) {
  return (
    <IconWrapper
      width={1.625 * scale}
      height={1.5625 * scale}
      aria-label={altText}
    >
      <Kpi />
    </IconWrapper>
  );
}

KpiIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string
};

KpiIcon.defaultProps = {
  scale: 1
};
