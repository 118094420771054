import React from 'react';
import PropTypes from 'prop-types';

import StyledText from './styled-text';
import Colors from '../../../styles/colors';
import Typography from '../../../styles/typography';

/**
 * This is the main text component that is using the default font Open Sans with font weight regular as default.
 *
 * This component is not meant to be placed directly into the actual application code, but to be used by other specific text components in the component library.
 * That is because we dont want to have multiple props to be passed with the component in the application code.
 *
 * The component is wrapped inside the three other components Emphasis, EmphasisLight and MetaInformation.
 *
 * Text component has four different props where one of them is a boolean one called italic. If true the font would be in italic style.
 */
export default function Text({ children, color, italic, fontWeight, size }) {
  return (
    <StyledText
      color={color}
      italic={italic}
      fontWeight={fontWeight}
      size={Text.sizes[size]}
    >
      {children}
    </StyledText>
  );
}

Text.propTypes = {
  /** Choose from the colorpalette which color you want the text to be displayed in. */
  color: PropTypes.string,
  /** If true the italic font style would be added for the text. */
  italic: PropTypes.bool,
  /**
   * Ignoring the fontWeight cause it should not be change.
   * If it needs to be change, then use one of the other text components.
   * @ignore
   */
  fontWeight: PropTypes.string,
  /** Choose the size: */
  size: PropTypes.oneOf([
    'extrasmall',
    'small',
    'normal',
    'large',
    'extralarge'
  ])
};

Text.defaultProps = {
  color: Colors.black,
  italic: false,
  fontWeight: Typography.text.normal,
  size: 'normal'
};

Text.sizes = {
  extrasmall: Typography.text.sizes.extrasmall,
  small: Typography.text.sizes.small,
  normal: Typography.text.sizes.normal,
  large: Typography.text.sizes.large,
  extralarge: Typography.text.sizes.extralarge
};
