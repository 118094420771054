import React from 'react';
import PropTypes from 'prop-types';
import StyledParagraph from './styled-paragraph';

/**
 * How to use the paragraph component:
 *
 * The Paragraph component displays received text-content as a styled paragraph(`<p>`).
 */

/**@component*/
export default function Paragraph({ children }) {
  return <StyledParagraph>{children}</StyledParagraph>;
}

Paragraph.propTypes = {
  children: PropTypes.node
};
