import styled from 'styled-components';
import Colors from '../../../styles/colors/colors';
import Typography from '../../../styles/typography/typography';

export const ICON_SCALE = 0.78125;

const calculateIconPadding = (valid) => {
  if (valid != null) {
    return ICON_SCALE * 2 + 1.625 + 'rem';
  }

  return '0.9375rem';
};

const setErrorStyle = (props) => {
  return props.valid === false ? Colors.negative : Colors.secondaryShadeLight;
};

const setNoteInputErrorStyle = (props) => {
  return props.valid === false && props.errorMessage
    ? Colors.negative
    : Colors.gray;
};

export const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: thin solid ${(props) => !props.disabled && setErrorStyle(props)};
  border-radius: 0.25rem;
  background-color: ${Colors.white};
  font-weight: ${Typography.text.normal};
  font-size: ${Typography.text.sizes.extralarge};
  font-family: ${Typography.text.name};
  text-align: left;
  padding-left: 0.9375rem;
  padding-right: ${(props) => calculateIconPadding(props.valid)};
  height: 3.4375rem;
  transition: box-shadow 0.2s ease-out;

  /* Removes spinner controls from input[type=number] */
  appearance: ${(props) =>
    props.type.toUpperCase() === 'NUMBER' && 'textfield'};
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: ${(props) =>
      props.type.toUpperCase() === 'NUMBER' && 'none'};
    margin: ${(props) => props.type.toUpperCase() === 'NUMBER' && 0};
  }

  /*Removes red border in Firefox when required-attribute is set to true */
  :required {
    box-shadow: none;
  }

  &::-ms-clear {
    display: none;
  }

  :focus {
    outline: none;
    box-shadow: 0 0 0.3125rem 0 ${(props) => setErrorStyle(props)};
    border: thin solid ${(props) => setErrorStyle(props)};
    transition: box-shadow 0.2s ease-out;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${(props) => (props.disabled ? Colors.gray : Colors.darkGray)};
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => (props.disabled ? Colors.gray : Colors.darkGray)};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => (props.disabled ? Colors.gray : Colors.darkGray)};
  }
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: initial;
`;

export const StyledInputContainerOuter = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
`;

export const StyledInputContainerInner = styled.div`
  position: relative;
  width: 100%;
  min-width: 8rem;
`;

export const StyledInputValidationIconWrapper = styled.span`
  position: absolute;
  top: 0.9375rem;
  right: 0.8125rem;
`;

export const StyledUnitWrapper = styled.div`
  display: flex;
  margin-left: 1rem;
  align-items: center;
`;

export const StyledErrorWrapper = styled.div`
  margin-top: 0.2rem;
`;

export const StyledLabel = styled.label`
  text-transform: uppercase;
  margin-bottom: 0.1875rem;
`;

export const StyledNoteInput = styled(StyledInput)`
  border: none;
  border-radius: 0;
  border-bottom: thin solid
    ${(props) => !props.disabled && setNoteInputErrorStyle(props)};
  font-size: ${Typography.text.sizes.normal};
  box-shadow: none;
  padding-left: 0;

  :focus {
    outline: none;
    border: none;
    border-bottom: thin solid
      ${(props) => !props.disabled && setNoteInputErrorStyle(props)};
    transition: box-shadow 0.2s ease-out;
    box-shadow: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${(props) => (props.disabled ? Colors.gray : Colors.darkGray)};
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => (props.disabled ? Colors.gray : Colors.darkGray)};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => (props.disabled ? Colors.gray : Colors.darkGray)};
  }
`;
