import React from 'react';
import PropTypes from 'prop-types';
import IconWrapper from '../icon-wrapper';
import TriangleRight from './triangle-right-icon.svg';

export default function TriangleRightIcon({ scale, altText }) {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      <TriangleRight />
    </IconWrapper>
  );
}

TriangleRightIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string
};

TriangleRightIcon.defaultProps = {
  scale: 1
};
